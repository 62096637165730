<template>
  <div style="height: 100%" class="w-100 card shadow-none">
    <div class="w-100 h-auto pl-1">
      <!-- <h3 class="card-title mb-50">
        Comments
        <b-badge v-if="comments.length > 0" class="ml-50" pill variant="warning">{{
          comments.length
        }}</b-badge>
      </h3> -->
    </div>
    <div class="card-body border-rounded px-0 py-50">
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showCommentLoading"
        rounded="md"
        variant="secondary"
      >
        <vue-perfect-scrollbar
          :settings="{ maxScrollbarLength: 60 }"
          style="max-height: 55vh"
        >
          <div
            class="d-flex flex-column justify-content-between align-items-center border px-1 py-1 rounded"
          >
            <template v-if="comments && comments != null && comments.length > 0">
              <template v-for="comment in comments">
                <template
                  v-if="
                    comment.addedBy &&
                    comment.addedBy.user_id === $store.state.app.user.user_id
                  "
                >
                  <current-user-comment-card
                    :key="comment._id"
                    :firstName="comment.addedBy.firstname"
                    :lastName="comment.addedBy.lastname"
                    :dateTime="comment.created_at"
                    :message="comment.message"
                  />
                </template>
                <template v-else>
                  <user-comment-card
                    :key="comment._id"
                    :firstName="comment.addedBy.firstname"
                    :lastName="comment.addedBy.lastname"
                    :dateTime="comment.created_at"
                    :message="comment.message"
                  />
                </template>
              </template>

              <!-- <div
                class="card w-100 py-50 border"
                :class="{
                  'border-primary':
                    comment.addedBy &&
                    comment.addedBy.user_id === $store.state.app.user.user_id,
                }"
                v-for="comment in comments"
                :key="comment._id"
              >
                <div
                  class="card-header d-flex flex-row align-items-center justify-content-between my-25 py-0"
                >
                  <span class="d-flex align-items-center justify-content-start">
                    <h5
                      v-if="comment.addedBy && comment.addedBy != null"
                      class="font-weight-bolder m-0"
                    >
                      {{ comment.addedBy.firstname }}
                      {{ comment.addedBy.lastname }}
                    </h5>
                    <b-badge v-else variant="danger">Deleted User</b-badge>
                  </span>
                  <p class="card-subtitle m-0">
                    {{ comment.created_at | moment }}
                  </p>
                </div>
                <div class="card-body pb-0 mt-25">
                  <p class="mb-0">{{ comment.message }}</p>
                </div>
              </div> -->
            </template>
            <template v-else>
              <div class="card w-100 mb-0 py-1">
                <div class="d-flex flex-column align-items-center justify-content-center">
                  <feather-icon icon="DatabaseIcon" size="32" class="mb-50" />
                  <h6 class="mb-25 font-weight-bolder">No Comments Found</h6>
                  <p class="mb-0" v-if="disableCreation === false">
                    You can add comments from the section below.
                  </p>
                </div>
              </div>
            </template>
          </div>
        </vue-perfect-scrollbar>
      </b-overlay>
      <div
        v-if="disableCreation === false"
        class="d-flex flex-column justify-content-start align-items-start pt-1"
      >
        <p class="mb-25 font-weight-bold">Write your comment</p>
        <b-form-textarea
          id="textarea"
          v-model="newComment"
          placeholder="Comment..."
          rows="2"
          max-rows="4"
        ></b-form-textarea>
        <div class="w-100 d-flex align-items-center justify-content-end">
          <b-button
            class="mt-50"
            @click="handleAddNewCommentClick"
            variant="success"
            :disabled="newComment === null || newComment === '' || showCommentLoading"
          >
            <span v-if="showCommentLoading" class="align-middle"
              ><b-spinner type="border" class="mr-50" small></b-spinner
              ><span class="align-middle">Please Wait</span></span
            >
            <span v-else
              ><feather-icon icon="SendIcon" class="mr-50" /><span class="align-middle"
                >Send</span
              ></span
            >
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BFormTextarea, BSpinner, BBadge, BOverlay } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from "moment";
import UtilsMixins from "../mixins/UtilsMixins";
import FeatherIcon from "../@core/components/feather-icon/FeatherIcon.vue";
import CurrentUserCommentCard from "./CurrentUserCommentCard.vue";
import UserCommentCard from "./UserCommentCard.vue";
export default {
  components: {
    VuePerfectScrollbar,
    BButton,
    BFormTextarea,
    BSpinner,
    BBadge,
    BOverlay,
    FeatherIcon,
    CurrentUserCommentCard,
    UserCommentCard,
  },
  mixins: [UtilsMixins],
  data() {
    return {
      newComment: null,
    };
  },
  props: {
    comments: {
      type: Array,
      required: true,
    },
    newCommentCallback: {
      type: Function,
      required: true,
    },
    showCommentLoading: {
      type: Boolean,
      required: true,
    },
    disableCreation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  methods: {
    clearText() {
      this.newComment = null;
    },
    handleAddNewCommentClick() {
      this.newCommentCallback(this.newComment).then((res) => {
        this.clearText();
      });
    },
  },
};
</script>

<style></style>
