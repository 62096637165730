<template>
  <div class="w-100 h-auto">
    <b-sidebar
      id="sidebar-question-details"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="620px"
    >
      <sidebar-template title="Question Details" :closeAction="closeDetailsSidebar">
        <template #content>
          <response-question-details-view
            :response="selectedResponse"
            :reloadParent="load"
            :assessmentStatus="selectedResponse.questionnaire.assessment.status"
            :disableActions="true"
          />
        </template>
      </sidebar-template>
    </b-sidebar>
    <div class="card">
      <div class="card-header pb-75">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="d-flex flex-column align-items-start justify-content-start">
            <h3 class="mb-25 font-weight-bolder">Risks</h3>
            <p class="mb-0">Risks of every assessments are listed below.</p>
          </div>

          <div class="d-flex flex-row align-items-center justify-content-end">
            <!-- <b-button
              size="sm"
              variant="success"
              class="mr-1"
              @click="handleNewPolicyClick"
              ><feather-icon icon="PlusIcon" class="mr-50" />New Policy</b-button
            >
            <b-button size="sm" variant="success" @click="handleImportPolicyClick"
              ><feather-icon icon="DownloadCloudIcon" class="mr-50" />Import from
              OneCSF</b-button
            > -->
          </div>
        </div>

        <div class="col mt-2 py-1 rounded border">
          <b-row class="d-flex justify-content-between mb-2">
            <h4 class="mx-2 text-dark font-weight-bold">
              Filters
              <feather-icon class="ml-55" size="20" icon="FilterIcon" />
            </h4>
            <b-button
              variant="outline-danger"
              class="mx-2"
              size="sm"
              @click="resetFilters"
              ><feather-icon icon="XIcon" class="mr-50" />
              <span class="align-middle">Clear Filters</span></b-button
            >
          </b-row>
          <b-row>
            <div class="col-sm-12">
              <div
                class="d-flex flex-wrap align-items-center justify-content-between w-100"
              >
                <b-form-group style="width: 24%">
                  <v-select
                    :class="{
                      highlighted: highlightedFilter == 'status',
                    }"
                    v-model="filters.status"
                    label="text"
                    :reduce="(opt) => opt.value"
                    :options="statusOptions"
                    placeholder="--Select Compliance Status--"
                  />
                </b-form-group>

                <b-form-group style="width: 24%">
                  <v-select
                    :class="{
                      highlighted: highlightedFilter == 'evidence_status',
                    }"
                    v-model="filters.evidence_status"
                    label="text"
                    :reduce="(opt) => opt.value"
                    :options="evidenceStatusOptions"
                    placeholder="--Select Evidence Status--"
                  />
                </b-form-group>

                <b-form-group style="width: 24%">
                  <v-select
                    :class="{
                      highlighted: highlightedFilter == 'risk_value',
                    }"
                    v-model="filters.risk_value"
                    label="text"
                    :reduce="(opt) => opt.value"
                    :options="riskValueOptions"
                    placeholder="--Select Risk Value--"
                  />
                </b-form-group>
                <b-form-group style="width: 24%">
                  <v-select
                    :class="{
                      highlighted: highlightedFilter == 'assessment_id',
                    }"
                    v-model="filters.assessment_id"
                    label="text"
                    :options="assessmentOptions"
                    placeholder="--Search & Select Assessments--"
                    @search="
                      (searchQuery) => {
                        getAssessmentsAndSetOptions({ search: searchQuery });
                      }
                    "
                  />
                </b-form-group>
              </div>
            </div>
          </b-row>
          <b-row>
            <div class="app-fixed-search col-sm-12">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    v-if="filters.search == null || filters.search == ''"
                    icon="SearchIcon"
                    class="text-muted"
                  />
                  <feather-icon
                    @click="
                      () => {
                        filters.search = null;
                      }
                    "
                    v-else
                    icon="XIcon"
                    class="text-danger cursor-pointer"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="filters.search"
                  placeholder="Search name..."
                  debounce="500"
                  size="md"
                />
              </b-input-group>
            </div>
          </b-row>
        </div>
      </div>

      <!-- <div class="pb-25 pt-0 w-100 row">
        <div class="app-fixed-search col-md-8 col-sm-12">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="filters.search == null || filters.search == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    filters.search = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filters.search"
              placeholder="Search..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>
      </div> -->
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <div class="position-relative table-responsive mb-0">
          <table role="table" class="table table-hover">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col">#</th>
                <!-- <th role="columnheader" scope="col">Ref.no</th> -->
                <!-- <th role="columnheader" scope="col">Question</th> -->
                <th role="columnheader" scope="col">Risk</th>
                <th role="columnheader" scope="col">Assessment</th>
                <!-- <th role="columnheader" scope="col">Third Party</th> -->
                <th role="columnheader" scope="col">Risk Value</th>
                <th role="columnheader" scope="col" class="text-center">Response</th>
                <th role="columnheader" scope="col" class="text-center">Compliance</th>
                <!-- <th role="columnheader" scope="col" class="text-center">Actions</th> -->
              </tr>
            </thead>
            <tbody role="rowgroup">
              <template v-if="responses && responses != null && responses.length > 0">
                <tr
                  v-for="(question, i) in responses"
                  :key="question._id"
                  role="row"
                  class="cursor-pointer"
                  @click="handleDetailsViewClick(question)"
                >
                  <th role="cell">
                    {{ i + pagination.from }}
                  </th>
                  <!-- <td>{{ question.question_number }}</td> -->
                  <td role="cell" style="max-width: 30vw">
                    <div class="w-100">
                      <p class="text-left font-weight-bold mb-25 text-primary">
                        {{ question.title }}
                      </p>
                      <small class="text-left mb-0">
                        {{ question.description }}
                      </small>
                    </div>
                  </td>
                  <td role="cell" style="max-width: 20vw">
                    <span
                      class="font-weight-semibold"
                      v-if="
                        question.questionnaire.assessment.title &&
                        question.questionnaire.assessment.title != null
                      "
                      >{{ question.questionnaire.assessment.title }}</span
                    >
                  </td>
                  <td role="cell">
                    <span
                      class="font-weight-semibold"
                      v-if="
                        question.questionnaire.risk_value &&
                        question.questionnaire.risk_value != null
                      "
                      ><b-badge
                        :variant="
                          question.questionnaire.risk_value === 1
                            ? 'success'
                            : question.questionnaire.risk_value === 2
                            ? 'warning'
                            : 'danger'
                        "
                      >
                        {{
                          question.questionnaire.risk_value === 1
                            ? "Low"
                            : question.questionnaire.risk_value === 2
                            ? "Medium"
                            : "high"
                        }}
                      </b-badge>
                    </span>
                  </td>
                  <td role="cell">
                    <div class="d-flex align-items-center justify-content-center w-100">
                      <b-badge
                        :variant="getResponseVariant(question.response)"
                        v-if="question.response && question.response != null"
                        >{{ getResponseValue(question.response) }}</b-badge
                      >
                    </div>
                  </td>

                  <td>
                    <div class="d-flex align-items-center justify-content-center w-100">
                      <b-badge
                        :variant="getResponseVariant(question.status)"
                        v-if="question.status && question.status != null"
                        >{{ getComplianceValue(question.status) }}</b-badge
                      >
                    </div>
                  </td>
                  <!-- <td role="cell">
                <div
                  class="d-flex align-items-center justify-content-between w-100"
                ></div>
              </td> -->
                </tr>
              </template>
              <template v-else>
                <empty-table-section title="Risks Empty">
                  <template #content>
                    <p class="text-center">It seems there are no risks at the moment.</p>
                  </template>
                </empty-table-section>
              </template>
            </tbody>
          </table>
        </div>
        <div class="card-body" v-if="responses && responses.length > 0">
          <div class="demo-spacing-0 d-flex align-items-center justify-content-between">
            <p class="align-middle mb-0">
              Showing {{ pagination.from }} to {{ pagination.to }} of
              {{ pagination.total }} entries
            </p>

            <b-pagination
              v-model="filters.page"
              :total-rows="pagination.total"
              :per-page="10"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>
  
  <script>
  import {
    BCard,
    BCardText,
    BLink,
    BButton,
    BPagination,
    BAvatar,
    BBadge,
    VBTooltip,
    BSidebar,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BDropdownGroup,
    BDropdownDivider,
    BModal,
    BInputGroupAppend,
    BSpinner,
    BRow,
    BFormGroup,
  } from "bootstrap-vue";
  import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
  import ResponseMixins from "@/mixins/ResponseMixins";
  import AssessmentResponsesMixins from "@/mixins/AssessmentResponsesMixins";
  
  import EmptyTableSection from "@/components/EmptyTableSection.vue";
  import ResponseQuestionDetailsView from "@/views/ThirdPartyRisks/ThirdPartyRisksRA/components/ResponseQuestionDetailsView.vue";
  import vSelect from "vue-select";
  import ThirdPartyRisksRAMixins from "@/mixins/ThirdPartyRisksRAMixins";
  import SidebarTemplate from "@/components/SidebarTemplate.vue";
  
  export default {
    components: {
      BCard,
      BCardText,
      BLink,
      BButton,
      BPagination,
      BAvatar,
      BBadge,
      FeatherIcon,
      BSidebar,
      BInputGroupPrepend,
      BInputGroup,
      BFormInput,
      BOverlay,
      BDropdown,
      BDropdownItem,
      BDropdownGroup,
      BDropdownDivider,
      BModal,
      BInputGroupAppend,
      BSpinner,
      EmptyTableSection,
      ResponseQuestionDetailsView,
      vSelect,
      BRow,
      BFormGroup,
      SidebarTemplate,
    },
    props:{
      vendorId: {
      type: Object,
      required: true,
    },
    },
    directives: {
      "b-tooltip": VBTooltip,
      highlight: {
        bind(el, binding) {
          if (binding.value) {
            el.classList.add("highlighted"); // Apply the highlight when the directive is bound
          }
        },
      },
    },
    mixins: [ResponseMixins, AssessmentResponsesMixins, ThirdPartyRisksRAMixins],
    data() {
      return {
        pagination: {
          total: 0,
          from: 0,
          to: 0,
        },
        responses: [],
        selectedResponse: null,
        filters: {
          search: null,
          page: 1,
          status: null,
          evidence_status: null,
          risk_value: null,
          assessment_id: null,
        },
        highlightedFilter: null,
        showOverlay: false,
        riskValueOptions: [
          { text: "Low", value: 1 },
          { text: "Medium", value: 2 },
          { text: "High", value: 3 },
        ],
        statusOptions: [
          { text: "Yes", value: 1 },
          { text: "No", value: 2 },
          { text: "Not Applicable", value: 3 },
        ],
        evidenceStatusOptions: [
          { text: "Requested", value: 1 },
          { text: "Uploaded", value: 2 },
          { text: "Approved", value: 3 },
          { text: "Rejected", value: 4 },
        ],
        assessmentOptions: [],
      };
    },
  
    watch: {
      filters: {
        handler: function (newValues) {
          this.getAssessmentResponsesAndSetData(newValues);
        },
        deep: true,
      },
    },
  
    mounted() {
      this.load();
    },
  
    methods: {
      load() {
        this.filters = this.getFilters();
        //   this.getAssessmentResponsesAndSetData(this.filters);
        this.getAssessmentsAndSetOptions({ limit: 10, page: 1 });
      },
  
      getAssessmentResponsesAndSetData(params) {
        const newParams = {};
        Object.keys(params).map((key) => {
          if (params[key] && params[key] != null) {
            if (key == "assessment_id") {
              if (typeof params[key] != "string") {
                console.log(params[key]);
                const obj = params[key];
                newParams[key] = obj.value;
              } else {
                newParams[key] = params[key];
              }
            } else {
              newParams[key] = params[key];
            }
          }
        });
        this.showOverlay = true;
        this.getAssessmentResponses({...newParams,vendor_id:this.vendorId})
          .then((res) => {
            console.log("Responses", res);
            this.responses = res.data.data.data;
            this.$set(this.pagination, "from", res.data.data.from);
            this.$set(this.pagination, "to", res.data.data.to);
            this.$set(this.pagination, "total", res.data.data.total);
          })
          .catch((err) => {
            this.handleError(err);
            console.log(err);
          })
          .finally(() => {
            this.showOverlay = false;
          });
      },
  
      getAssessmentsAndSetOptions(params) {
        this.getAssessments({...params,vendor_id:this.vendorId})
          .then((res) => {
            this.assessmentOptions = res.data.data.data.map((p) => {
              return { text: p.title, value: p._id };
            });
          })
          .catch((err) => {
            this.handleError(err);
            console.log(err);
          });
      },
  
      getFilters() {
        const queryParams = this.$route.query;
        const newFilters = { ...this.filters };
        if ("status" in queryParams) {
          newFilters["status"] = Number(queryParams["status"]);
          this.setAnimation("status");
        }
        if ("evidence_status" in queryParams) {
          newFilters["evidence_status"] = Number(queryParams["evidence_status"]);
          this.setAnimation("evidence_status");
        }
        if ("risk_value" in queryParams) {
          newFilters["risk_value"] = Number(queryParams["risk_value"]);
          this.setAnimation("risk_value");
        }
        if ("assessment_id" in queryParams) {
          newFilters["assessment_id"] = queryParams["assessment_id"];
          this.setAnimation("assessment_id");
        }
        this.$router.replace({ query: null });
        return newFilters;
      },
  
      setAnimation(filter) {
        this.highlightedFilter = filter;
        setTimeout(() => {
          this.highlightedFilter = null;
        }, 1000);
      },
  
      resetFilters() {
        const newFilters = {
          ...this.filters,
          search: null,
          status: null,
          evidence_status: null,
          risk_value: null,
          assessment_id: null,
        };
        this.filters = newFilters;
      },
  
      handleDetailsViewClick(response) {
        this.selectedResponse = response;
        this.openDetailsSidebar();
      },
  
      closeDetailsSidebar: function () {
        this.$root.$emit("bv::toggle::collapse", "sidebar-question-details");
      },
      openDetailsSidebar: function () {
        this.$nextTick().then(() => {
          this.$root.$emit("bv::toggle::collapse", "sidebar-question-details");
        });
      },
  
      getResponseValue(num) {
        if (num !== null) {
          let newNum = Number(num);
          switch (newNum) {
            case 1:
              return "Yes";
            case 2:
              return "No";
            case 3:
              return "Not Applicable";
            default:
              return "Empty";
          }
        }
      },
  
      getComplianceValue(num) {
        if (num !== null) {
          let newNum = Number(num);
          switch (newNum) {
            case 1:
              return "Yes";
            case 2:
              return "No";
            case 3:
              return "Not Applicable";
            default:
              return "Empty";
          }
        }
        return "Empty";
      },
  
      getResponseVariant(num) {
        if (num !== null) {
          let newNum = Number(num);
          switch (newNum) {
            case 1:
              return "success";
            case 2:
              return "danger";
            case 3:
              return "light-dark";
            default:
              return "light-dark";
          }
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .table th {
    text-transform: none;
  }
  
  .filters_section {
    background-color: #f2f0f7;
  }
  
  .dark-layout {
    .filters_section {
      background-color: #161d31;
    }
  }
  </style>
  
  <!-- <style lang="scss">
  .app-fixed-search {
    background-color: transparent;
  
    .input-group:focus-within {
      box-shadow: none;
    }
  
    input,
    .input-group-text {
      border: 0;
      background-color: transparent;
    }
  }
  </style> -->
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  
  <style scoped>
  .highlighted {
    animation: breathingEffect 1s infinite ease-in-out; /* Apply breathing animation for 7 seconds */
  }
  
  @keyframes breathingEffect {
    0% {
      border: 1px solid #00cfe8;
      box-shadow: 0 0 10px 10px #00d1e883;
    }
    50% {
      border: 1px solid transparent;
      box-shadow: none;
    }
    100% {
      border: 1px solid #00cfe8;
      box-shadow: 0 0 10px 10px #00d1e883;
    }
  }
  </style>
  