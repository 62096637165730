<template>
  <div
    style="height: 500px; min-width: 300px"
    class="card bg-light-secondary rounded"
  >
    <div class="d-flex justify-content-center align-items-center">
      <h5 class="text-center" style="margin: 6px auto 12px">
        {{ title }}
      </h5>
    </div>
    <div style="width: 100%">
      <b-input-group>
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          debounce="250"
          v-model="searchPhrase"
          placeholder="Search..."
          style="border-right: none; border-left: none; border-radius: 0"
        ></b-form-input>
        <b-input-group-append
          v-if="!excludedList.includes(searchPhrase)"
          is-text
        >
          <feather-icon
            @click="clearFilters"
            class="cursor-pointer"
            icon="XIcon"
          />
        </b-input-group-append>
      </b-input-group>
    </div>
    <div class="card-body bg-light-secondary main">
      <vue-perfect-scrollbar
        :settings="{ maxScrollbarLength: 120 }"
        class="w-100 list-group scroll-area"
      >
        <template v-for="item in items">
          <div
            :key="item._id"
            class="bg-white cursor-pointer rounded item"
            :id="item._id"
          >
            <b-tooltip
              v-if="allowTooltip"
              :target="item._id"
              :title="item.name ? item.name : item.title"
            ></b-tooltip>
            <span class="font-weight-bold text-truncate">{{
              item.name ? item.name : item.title
            }}</span>
            <div
              v-if="!buttonAddType"
              @click="handleButtonFunctionClick(item)"
              class="small-button bg-danger cursor-pointer"
            >
              <feather-icon class="text-white" icon="XIcon" />
            </div>
            <div
              v-if="buttonAddType"
              @click="handleButtonFunctionClick(item)"
              class="small-button bg-success cursor-pointer"
            >
              <feather-icon class="text-white" icon="PlusIcon" />
            </div>
          </div>
        </template>
      </vue-perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BOverlay,
  BFormInput,
  BInputGroupPrepend,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BTooltip,
  VBHover,
  VBTooltip,
} from "bootstrap-vue";
import FeatherIcon from "../@core/components/feather-icon/FeatherIcon.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  name: "AddOrRemoveList",
  components: {
    BBadge,
    FeatherIcon,
    BOverlay,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BInputGroupAppend,
    VuePerfectScrollbar,
    BButton,
    VBHover,
    VBTooltip,
    BTooltip,
  },
  directives: {
    "b-hover": VBHover,
    "b-tooltip": VBTooltip,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    buttonAddType: {
      type: Boolean,
      required: false,
      default: true,
    },
    buttonFunction: {
      type: Function,
      required: true,
    },
    searchFunction: {
      type: Function,
      required: true,
    },
    allowTooltip: {
      type: Boolean,
      default: false,
      required: false,
    },
    returnTypeIsObject:{
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    searchPhrase(val) {
      this.searchFunction(val);
    },
  },
  data() {
    return {
      searchPhrase: null,
      excludedList: [null, ""],
    };
  },

  methods: {
    handleButtonFunctionClick(item){
      if(this.returnTypeIsObject===true){
        return this.buttonFunction(item)
      }
      else{
        return this.buttonFunction(item._id)
      }
      
    },
    clearFilters() {
      this.searchPhrase = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-group-text {
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  .item {
    width: 95%;
    height: 30px;
    min-height: 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: auto 6px;
    margin: 6px auto;
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: scale(1.05);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    span {
      width: 80%;
    }
    .small-button {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      transition: transform 0.6s linear;
      &:hover {
        transform: rotate(360deg);
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }
    }
  }
}
</style>