export default {
    name: 'TaskMixins',
    methods: {
        getMonthFromNumber(data) {
            const num = new Date(data).getMonth();
            var months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];
            const month = months[num];
            return month;
        },
        getTasks(params) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    url: `${process.env.VUE_APP_BASEURL}/tpr/tasks`,
                    params: params,
                };
                this.$http(options)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
        },
        // addComment(message, taskId) {
        //     return new Promise((resolve, reject) => {
        //         const bodyFormData = new FormData();
        //         bodyFormData.append("message", message);
        //         const options = {
        //             method: "POST",
        //             headers: { "content-type": "application/x-www-form-urlencoded" },
        //             url: `${process.env.VUE_APP_BASEURL}/tasks/${taskId}/add-comment`,
        //             data: bodyFormData,
        //         };
        //         this.$http(options)
        //             .then(res => {
        //                 resolve(res)
        //             })
        //             .catch(err => reject(err))
        //     })
        // },
        updateTask(obj) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                if (obj.title !== null) {
                    bodyFormData.append("title", obj.title);
                }
                if (obj.status !== null) {
                    bodyFormData.append("status", obj.status);
                }
                if (obj.assignee.user_id !== null) {
                    bodyFormData.append("assignee", obj.assignee.user_id);
                }
                if (obj.due_date !== null) {
                    bodyFormData.append("due_date", obj.due_date);
                }
                if (obj.priority !== null) {
                    bodyFormData.append("priority", obj.priority);
                }
                if (obj.description !== null) {
                    bodyFormData.append("description", obj.description);
                }

                bodyFormData.append("_method", "PUT");


                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tasks/${obj.id}`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => {
                        resolve(res);
                    })
                    .catch(err => reject(err))
            })
        },
        updateTaskStatus(obj) {
            
            return new Promise((resolve, reject) => {

                const bodyFormData = new FormData();
                bodyFormData.append("status", obj.status);
                bodyFormData.append("_method", "PUT");
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tasks/${obj.id}/status`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
        },
    }

}