<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
    class="h-100"
  >
    <div class="card h-100">
      <div class="card-header border-bottom d-flex">
        <div
          style="width: 70%"
          class="d-flex flex-column justify-content-center align-items-start"
        >
          <h2 class="font-weight-bolder">
            <span class="align-middle"
              >Add new Questionnaire to vendor</span
            >
          </h2>
          <p class="card-subtitle text-muted pt-75">
            Click the add button on the items to add Questionnaire to vendor.
          </p>
        </div>

        <div class="d-flex justify-content-end align-items-center">
           <b-button size="sm" @click="closeSidebar()" variant="outline-secondary"
            ><feather-icon size="24" icon="XIcon"
          /></b-button>
        </div>
      </div>
      <div class="card-body pt-3 h-100" v-if="questionnaire!==null">
        <add-or-remove-list
          title="Non Imported Questionnaire"
          :buttonAddType="true"
          :buttonFunction="handleAddButtonClick"
          :searchFunction="handleSearchFunction"
          :items="questionnaire"
          :allowTooltip="true"
        />
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min } from "@validations";

import UtilsMixins from "../../../mixins/UtilsMixins";
import ThirdPartyRisksMixins from "../../../mixins/ThirdPartyRisksMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import AddOrRemoveList from "../../../components/AddOrRemoveList.vue";

export default {
  components: {
    BOverlay,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    required,
    min,
    AddOrRemoveList,
  },
  data() {
    return {
      showOverlay: false,
      questionnaire: null,
      filters: {
        search: null,
      },
    };
  },
  mixins: [UtilsMixins, ThirdPartyRisksMixins, ResponseMixins],
  props: {
    closeSidebar: {
      type: Function,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
    vendorId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.load();
  },

  watch: {
    filters: {
      handler: function (newValue) {
        this.getQuestionnaireAndSetData(newValue);
      },
      deep: true,
    },
  },
  methods: {
    load() {
      this.getQuestionnaireAndSetData(this.filters);
    },
    

    getQuestionnaireAndSetData(params) {
      this.showOverlay = true;
      this.getImportedQuestionnaires(params)
        .then((res) => {
          this.questionnaire = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleSearchFunction(searchPhrase) {
      console.log(searchPhrase);
      this.filters.search = searchPhrase;
    },
    handleAddButtonClick(questionnaireId) {
      this.showOverlay = true;
      this.assignQuestionnaireToVendor(
        { questionnaireId: questionnaireId },
        this.vendorId
      )
        .then((res) => {
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
          this.reloadParent();
        });
    },
  },
};
</script>

<style>
</style>