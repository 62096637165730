export default {
  methods: {
    addQuestionToQuestionnaire(obj, questionnaireId) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        if (obj.title !== null) {
          bodyFormData.append("title", obj.title);
        }
        if (obj.description !== null) {
          bodyFormData.append("description", obj.description);
        }
        if (obj.evidenceRequired !== null) {
          bodyFormData.append(
            "is_evidence_rqd",
            obj.evidenceRequired == "true" ? 1 : 0
          );
        }
        if (obj.responseMandatory !== null) {
          bodyFormData.append(
            "is_response_mandatory",
            obj.responseMandatory == "true" ? 1 : 0
          );
        }
        if (obj.justificationRequired !== null) {
          bodyFormData.append(
            "is_justification_mandatory",
            obj.justificationRequired == true ? 1 : 0
          );
        }
        if (obj.policies.length > 0) {
          obj.policies.forEach((p, index) => {
            bodyFormData.append(`policy_ids[${index}]`, p);
          });
        }

        if (obj.answer !== null) {
          bodyFormData.append("answer", obj.answer);
        }
        if (obj.parentQuestionId !== null) {
          bodyFormData.append("parent_question_id", obj.parentQuestionId);
        }
        if (obj.parentQuestionResponse !== null) {
          bodyFormData.append(
            "parent_question_response",
            obj.parentQuestionResponse
          );
        }
        if (obj.riskValueYes !== null) {
          bodyFormData.append("risk_values[yes]", obj.riskValueYes);
        }
        if (obj.riskValueNo !== null) {
          bodyFormData.append("risk_values[no]", obj.riskValueNo);
        }
        if (obj.riskValueNotApplicable !== null) {
          bodyFormData.append(
            "risk_values[not_applicable]",
            obj.riskValueNotApplicable
          );
        }
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/${questionnaireId}/questions`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    updateQuestionV2(data, assessmentId, questionnaireId, questionId) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        Object.keys(data).forEach((key) => {
          if (data[key] && data[key] != null) {
            bodyFormData.append(key, data[key]);
          }
        });

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/assessments/${assessmentId}/questionnaires/${questionnaireId}/responses/${questionId}`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    updateQuestionOnQuestionnaire(obj, questionnaireId, questionId) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        if (obj.title !== null) {
          bodyFormData.append("title", obj.title);
        }
        if (obj.description !== null) {
          bodyFormData.append("description", obj.description);
        }
        if (obj.evidenceRequired !== null) {
          bodyFormData.append(
            "is_evidence_rqd",
            obj.evidenceRequired == true ? 1 : 0
          );
        }
        if (obj.responseMandatory !== null) {
          bodyFormData.append(
            "is_response_mandatory",
            obj.responseMandatory == true ? 1 : 0
          );
        }
        if (obj.justificationRequired !== null) {
          bodyFormData.append(
            "is_justification_mandatory",
            obj.justificationRequired == true ? 1 : 0
          );
        }
        if (obj.answer !== null) {
          bodyFormData.append("answer", obj.answer);
        }
        if (obj.policies.length > 0) {
          obj.policies.forEach((p, index) => {
            bodyFormData.append(`policy_ids[${index}]`, p);
          });
        }
        if (obj.parentQuestionId !== null) {
          bodyFormData.append("parent_question_id", obj.parentQuestionId);
        }
        if (obj.parentQuestionResponse !== null) {
          bodyFormData.append(
            "parent_question_response",
            obj.parentQuestionResponse
          );
        }
        if (obj.riskValueYes !== null) {
          bodyFormData.append("risk_values[yes]", obj.riskValueYes);
        }
        if (obj.riskValueNo !== null) {
          bodyFormData.append("risk_values[no]", obj.riskValueNo);
        }
        if (obj.riskValueNotApplicable !== null) {
          bodyFormData.append(
            "risk_values[not_applicable]",
            obj.riskValueNotApplicable
          );
        }
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/${questionnaireId}/questions/${questionId}`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getQuestionsFromQuestionnaire(params, questionnaireId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/${questionnaireId}/questions`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getQuestionDetails(questionnaireId, questionId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/${questionnaireId}/questions/${questionId}`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    removeQuestionFromQuestionnaire(questionnaireId, questionId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/${questionnaireId}/questions/${questionId}`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getImportedQuestionnaires(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getImportedQuestionnaireDetails(questionnaireId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/${questionnaireId}`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    createCustomQuestionnaire(obj) {
      return new Promise((resolve, reject) => {
        // const bodyFormData = new FormData();
        // if (obj.title !== null) {
        //     bodyFormData.append("title", obj.title);
        // }
        // if (obj.description !== null) {
        //     bodyFormData.append("description", obj.description);
        // }
        // // if (obj.hasOwnProperty('evidence_rqd')) {
        //     const settings = {
        //         "evidence_required": obj.evidence_rqd,
        //         "response_mandatory": obj.res_mandatory,
        //         "justification": obj.justification
        //     };
        //     bodyFormData.append("settings", JSON.stringify(settings));
        // // }
        // const data = {
        //     "title":obj.title,
        //     "description":obj.description,
        //     "settings":{
        //         "evidence_required": obj.evidence_rqd,
        //         "response_mandatory": obj.res_mandatory,
        //         "justification": obj.justification
        //     }
        // };
        const formData = new FormData();
        formData.append("title", obj.title);
        formData.append("description", obj.description);
        formData.append("settings[evidence_required]", obj.evidence_rqd);
        formData.append("settings[response_mandatory]", obj.res_mandatory);
        formData.append("settings[justification]", [obj.justification]);

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires`,
          data: formData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    removeQuestionnaire(questionnaireId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/${questionnaireId}`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getImportedQuestionnaireQuestions(params, questionnaireId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/:${questionnaireId}/questions`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getNonImportedQuestionnaire(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/non-imported`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getNonImportedQuestionnaireDetails(params, questionnaireId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/non-imported/${questionnaireId}`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    importNonImportedQuestionnaire(questionnaireId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/non-imported/${questionnaireId}/import`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
