var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 h-auto"},[_c('b-sidebar',{attrs:{"id":"sidebar-question-details","sidebar-class":"sidebar-lg","bg-variant":"white","backdrop":"","right":"","no-header":"","shadow":"","lazy":"","width":"620px"}},[_c('sidebar-template',{attrs:{"title":"Question Details","closeAction":_vm.closeDetailsSidebar},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('response-question-details-view',{attrs:{"response":_vm.selectedResponse,"reloadParent":_vm.load,"assessmentStatus":_vm.selectedResponse.questionnaire.assessment.status,"disableActions":true}})]},proxy:true}])})],1),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header pb-75"},[_vm._m(0),_c('div',{staticClass:"col mt-2 py-1 rounded border"},[_c('b-row',{staticClass:"d-flex justify-content-between mb-2"},[_c('h4',{staticClass:"mx-2 text-dark font-weight-bold"},[_vm._v(" Filters "),_c('feather-icon',{staticClass:"ml-55",attrs:{"size":"20","icon":"FilterIcon"}})],1),_c('b-button',{staticClass:"mx-2",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":_vm.resetFilters}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Clear Filters")])],1)],1),_c('b-row',[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"d-flex flex-wrap align-items-center justify-content-between w-100"},[_c('b-form-group',{staticStyle:{"width":"24%"}},[_c('v-select',{class:{
                    highlighted: _vm.highlightedFilter == 'status',
                  },attrs:{"label":"text","reduce":function (opt) { return opt.value; },"options":_vm.statusOptions,"placeholder":"--Select Compliance Status--"},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1),_c('b-form-group',{staticStyle:{"width":"24%"}},[_c('v-select',{class:{
                    highlighted: _vm.highlightedFilter == 'evidence_status',
                  },attrs:{"label":"text","reduce":function (opt) { return opt.value; },"options":_vm.evidenceStatusOptions,"placeholder":"--Select Evidence Status--"},model:{value:(_vm.filters.evidence_status),callback:function ($$v) {_vm.$set(_vm.filters, "evidence_status", $$v)},expression:"filters.evidence_status"}})],1),_c('b-form-group',{staticStyle:{"width":"24%"}},[_c('v-select',{class:{
                    highlighted: _vm.highlightedFilter == 'risk_value',
                  },attrs:{"label":"text","reduce":function (opt) { return opt.value; },"options":_vm.riskValueOptions,"placeholder":"--Select Risk Value--"},model:{value:(_vm.filters.risk_value),callback:function ($$v) {_vm.$set(_vm.filters, "risk_value", $$v)},expression:"filters.risk_value"}})],1),_c('b-form-group',{staticStyle:{"width":"24%"}},[_c('v-select',{class:{
                    highlighted: _vm.highlightedFilter == 'assessment_id',
                  },attrs:{"label":"text","options":_vm.assessmentOptions,"placeholder":"--Search & Select Assessments--"},on:{"search":function (searchQuery) {
                      _vm.getAssessmentsAndSetOptions({ search: searchQuery });
                    }},model:{value:(_vm.filters.assessment_id),callback:function ($$v) {_vm.$set(_vm.filters, "assessment_id", $$v)},expression:"filters.assessment_id"}})],1)],1)])]),_c('b-row',[_c('div',{staticClass:"app-fixed-search col-sm-12"},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[(_vm.filters.search == null || _vm.filters.search == '')?_c('feather-icon',{staticClass:"text-muted",attrs:{"icon":"SearchIcon"}}):_c('feather-icon',{staticClass:"text-danger cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function () {
                      _vm.filters.search = null;
                    }}})],1),_c('b-form-input',{attrs:{"placeholder":"Search name...","debounce":"500","size":"md"},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)],1)])],1)]),_c('b-overlay',{attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[_c('div',{staticClass:"position-relative table-responsive mb-0"},[_c('table',{staticClass:"table table-hover",attrs:{"role":"table"}},[_c('thead',{attrs:{"role":"rowgroup"}},[_c('tr',{attrs:{"role":"row"}},[_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("#")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Risk")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Assessment")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Risk Value")]),_c('th',{staticClass:"text-center",attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Response")]),_c('th',{staticClass:"text-center",attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Compliance")])])]),_c('tbody',{attrs:{"role":"rowgroup"}},[(_vm.responses && _vm.responses != null && _vm.responses.length > 0)?_vm._l((_vm.responses),function(question,i){return _c('tr',{key:question._id,staticClass:"cursor-pointer",attrs:{"role":"row"},on:{"click":function($event){return _vm.handleDetailsViewClick(question)}}},[_c('th',{attrs:{"role":"cell"}},[_vm._v(" "+_vm._s(i + _vm.pagination.from)+" ")]),_c('td',{staticStyle:{"max-width":"30vw"},attrs:{"role":"cell"}},[_c('div',{staticClass:"w-100"},[_c('p',{staticClass:"text-left font-weight-bold mb-25 text-primary"},[_vm._v(" "+_vm._s(question.title)+" ")]),_c('small',{staticClass:"text-left mb-0"},[_vm._v(" "+_vm._s(question.description)+" ")])])]),_c('td',{staticStyle:{"max-width":"20vw"},attrs:{"role":"cell"}},[(
                      question.questionnaire.assessment.title &&
                      question.questionnaire.assessment.title != null
                    )?_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(question.questionnaire.assessment.title))]):_vm._e()]),_c('td',{attrs:{"role":"cell"}},[(
                      question.questionnaire.risk_value &&
                      question.questionnaire.risk_value != null
                    )?_c('span',{staticClass:"font-weight-semibold"},[_c('b-badge',{attrs:{"variant":question.questionnaire.risk_value === 1
                          ? 'success'
                          : question.questionnaire.risk_value === 2
                          ? 'warning'
                          : 'danger'}},[_vm._v(" "+_vm._s(question.questionnaire.risk_value === 1 ? "Low" : question.questionnaire.risk_value === 2 ? "Medium" : "high")+" ")])],1):_vm._e()]),_c('td',{attrs:{"role":"cell"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center w-100"},[(question.response && question.response != null)?_c('b-badge',{attrs:{"variant":_vm.getResponseVariant(question.response)}},[_vm._v(_vm._s(_vm.getResponseValue(question.response)))]):_vm._e()],1)]),_c('td',[_c('div',{staticClass:"d-flex align-items-center justify-content-center w-100"},[(question.status && question.status != null)?_c('b-badge',{attrs:{"variant":_vm.getResponseVariant(question.status)}},[_vm._v(_vm._s(_vm.getComplianceValue(question.status)))]):_vm._e()],1)])])}):[_c('empty-table-section',{attrs:{"title":"Risks Empty"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"text-center"},[_vm._v("It seems there are no risks at the moment.")])]},proxy:true}])})]],2)])]),(_vm.responses && _vm.responses.length > 0)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"demo-spacing-0 d-flex align-items-center justify-content-between"},[_c('p',{staticClass:"align-middle mb-0"},[_vm._v(" Showing "+_vm._s(_vm.pagination.from)+" to "+_vm._s(_vm.pagination.to)+" of "+_vm._s(_vm.pagination.total)+" entries ")]),_c('b-pagination',{attrs:{"total-rows":_vm.pagination.total,"per-page":10,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388),model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1)]):_vm._e()])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center justify-content-between w-100"},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start"},[_c('h3',{staticClass:"mb-25 font-weight-bolder"},[_vm._v("Risks")]),_c('p',{staticClass:"mb-0"},[_vm._v("Risks of every assessments are listed below.")])]),_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-end"})])}]

export { render, staticRenderFns }