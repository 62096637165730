export default {
    methods: {
      getComments(assessmentId, questionnaireId, questionId) {
        return new Promise((resolve, reject) => {
          const options = {
            method: "GET",
            url: `${process.env.VUE_APP_BASEURL}/tpr/assessments/${assessmentId}/questionnaires/${questionnaireId}/responses/${questionId}/comments`,
          };
          this.$http(options)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
        });
      },
  
      addNewComment(assessmentId, questionnaireId, questionId, data) {
        return new Promise((resolve, reject) => {
          const bodyFormData = new FormData();
          bodyFormData.append("message", data.comment);
          const options = {
            method: "POST",
            headers: { "content-type": "multipart/form-data" },
            url: `${process.env.VUE_APP_BASEURL}/tpr/assessments/${assessmentId}/questionnaires/${questionnaireId}/responses/${questionId}/comments/add`,
            data: bodyFormData,
          };
          this.$http(options)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
        });
      },
  
      deleteComment(assessmentId, questionnaireId, questionId, commentId) {
        return new Promise((resolve, reject) => {
          const options = {
            method: "DELETE",
            url: `${process.env.VUE_APP_BASEURL}/tpr/assessments/${assessmentId}/questionnaires/${questionnaireId}/responses/${questionId}/comments/${commentId}/remove`,
          };
          this.$http(options)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
        });
      },
  
      getVendorComments(vendorId, assessmentId, questionnaireId, questionId) {
        return new Promise((resolve, reject) => {
          const options = {
            method: "GET",
            url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}/assessments/${assessmentId}/questionnaires/${questionnaireId}/responses/${questionId}/comments`,
          };
          this.$http(options)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
        });
      },
  
      addNewVendorComment(
        vendorId,
        assessmentId,
        questionnaireId,
        questionId,
        data
      ) {
        return new Promise((resolve, reject) => {
          const bodyFormData = new FormData();
          bodyFormData.append("message", data.comment);
          const options = {
            method: "POST",
            headers: { "content-type": "multipart/form-data" },
            url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}/assessments/${assessmentId}/questionnaires/${questionnaireId}/responses/${questionId}/comments/add`,
            data: bodyFormData,
          };
          this.$http(options)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
        });
      },
  
      deleteVendorComment(
        vendorId,
        assessmentId,
        questionnaireId,
        questionId,
        commentId
      ) {
        return new Promise((resolve, reject) => {
          const options = {
            method: "DELETE",
            url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}/assessments/${assessmentId}/questionnaires/${questionnaireId}/responses/${questionId}/comments/${commentId}/remove`,
          };
          this.$http(options)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
        });
      },
    },
  };
  