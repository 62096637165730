<template>
  <div class="w-100 d-flex align-items-end justify-content-end mb-2">
    <div
      class="rounded bg-light-secondary px-1 py-75 d-flex flex-column align-items-start justify-content-between"
      style="min-width: 40%; max-width: 80%"
    >
      <p class="text-dark mb-25">{{ message }}</p>

      <div class="w-100 d-flex align-items-center justify-content-start">
        <div>
          <p class="font-weight-bold mb-0 text-dark">
            <span class="d-flex align-items-center justify-content-start">
              <feather-icon icon="UserIcon" size="14" class="mr-25" />
              <span>{{ `${firstName} ${lastName}` }}</span>
            </span>
          </p>
        </div>
        <div class="ml-2">
          <p
            class="font-weight-bold mb-0 cursor-pointer"
            v-b-tooltip.hover
            :title="formatDateTime(dateTime)"
          >
            <span class="d-flex align-items-center justify-content-start">
              <feather-icon icon="ClockIcon" size="14" class="mr-25" />
              <span>
                {{ formatDate(dateTime) }}
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>

    <b-avatar
      class="ml-1"
      variant="light-primary"
      :text="avatarText(`${firstName} ${lastName}`)"
    />
  </div>
</template>

<script>
import { BAvatar, VBTooltip } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import moment from "moment";

export default {
  setup() {
    return { avatarText };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: { BAvatar },
  props: {
    firstName: {
      type: String,
      required: true,
    },

    lastName: {
      type: String,
      required: true,
    },

    message: {
      type: String,
      required: true,
    },

    dateTime: {
      type: String,
      required: true,
    },
  },
  filters: {
    formatMoment: function (date) {
      return moment(date).format("D-MMM-YY h:ss");
    },
  },

  methods: {
    formatDate(date) {
      return moment(moment(date), "D-MMM-YY h:ss").fromNow()
      // const daysAgo = moment().diff(date, "days");
      // const weeksAgo = moment().diff(date, "weeks");
      // const monthsAgo = moment().diff(date, "months");

      // if (daysAgo === 0) {
      //   return "Today";
      // } else if (daysAgo === 1) {
      //   return "Yesterday";
      // } else if (daysAgo < 7) {
      //   return `${daysAgo} days ago`;
      // } else if (weeksAgo === 1) {
      //   return "1 week ago";
      // } else if (weeksAgo < 4) {
      //   return `${weeksAgo} weeks ago`;
      // } else if (monthsAgo === 1) {
      //   return "1 month ago";
      // } else {
      //   return `${monthsAgo} months ago`;
      // }
    },

    formatDateTime(date) {
      // Utilize Moment.js to format date and time
      return moment(date).format("D-MMM-YY");
    },

    getDateDifference(startDate, endDate) {
      const timestamp1 = new Date(startDate).getTime();
      const timestamp2 = new Date(endDate).getTime();

      const timeDifference = Math.abs(timestamp2 - timestamp1);
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      return daysDifference;
    },
  },
};
</script>

<style></style>
