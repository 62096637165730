<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div
      class="w-100 h-100 d-flex flex-column align-items-start justify-content-start"
      v-if="response && response != null"
    >
      <div class="d-flex flex-column align-items-center justify-content-start w-100">
        <div
          class="d-flex flex-column align-items-start justify-content-start mb-1 w-100"
        >
          <b-badge variant="warning" pill class="mb-25"
            ><span class="d-flex align-items-center justify-content-start">
              <span>Question</span>
              <feather-icon icon="HelpCircleIcon" class="ml-25" /> </span
          ></b-badge>
          <h5 class="mb-0">{{ response.title }}</h5>
          <p class="mb-0">{{ response.description }}</p>
        </div>

        <div
          class="w-100 d-flex align-items-center justify-content-around border-top border-bottom py-1"
        >
          <div class="d-flex flex-column align-items-center justify-content-start">
            <b-badge variant="success" pill class="mb-50"
              ><span class="d-flex align-items-center justify-content-start">
                <span>Response</span>
                <feather-icon icon="InfoIcon" class="ml-25" /> </span
            ></b-badge>
            <h5 class="mb-0 font-weight-bolder text-dark">
              {{ getResponseValue(response.response) }}
            </h5>
          </div>

          <div class="d-flex flex-column align-items-center justify-content-start">
            <b-badge variant="primary" pill class="mb-50"
              ><span class="d-flex align-items-center justify-content-start">
                <span>Compliance</span>
                <feather-icon icon="InfoIcon" class="ml-25" /> </span
            ></b-badge>
            <h5 class="mb-0 font-weight-bolder text-dark">
              {{ getComplianceValue(response.status) }}
            </h5>
          </div>
        </div>
      </div>

      <div
        class="w-100 d-flex align-items-center justify-content-center my-2"
        v-if="assessmentStatus == 1 && disableActions === false"
      >
        <b-button v-if="response.status===1 "  class="mr-1" size="sm" variant="danger" id="apply-noncompliance-popover"
          ><feather-icon icon="XCircleIcon" class="mr-50" /><span class="align-middle"
            >Apply Non-compliance</span
          ></b-button
        >
        <b-button v-if="response.response===1 " id="remediation-popover" class="mr-1" size="sm" variant="outline-dark"
          ><feather-icon icon="InfoIcon" class="mr-50" /><span class="align-middle"
            >Request Remediation</span
          ></b-button
        >
      </div>
      <b-modal :centered="true"  :hide-footer="true" header-bg-variant="primary" v-model="reviewPopoverShow" title="Add Reviewers"  @shown="loadReviewers">
        
        <validation-observer ref="reviewer-form" #default="{ invalid }" >
          <b-form @submit.prevent="addReviewer" id="reviewer-form">
            <b-form-group>
              <validation-provider #default="{ errors }" name="teams"  rules="required">
                
                <!-- <div v-for="option in teamOptions.options" :key="option.value"> -->
                  <b-form-group label="Teams">
                    <template #label>
                      Teams <span class="text-danger">*</span>
                    </template>
                    <v-select
                      v-model="selectedTeam"
                      :options="teamOptions.options"
                      :filterable="false"
                      :loading="teamLoading"
                      @search="fetchOptions"
                      :reduce="item => item"
                      label="text"
                      placeholder="Select a Team..."
                    >
                      <template #no-options>
                        <span >Sorry,no matching options</span>
                        <!-- <span v-else>Type to search</span> -->
                      </template>
                    </v-select>
                  </b-form-group>
                  <!-- <b-form-radio v-model="selectedTeam" name="some-radios" :value="option.value">{{ option.text }}</b-form-radio> -->
                  
                <!-- </div> -->
                <div class="text-center">

                <b-spinner v-if="teamLoading" variant="primary" small></b-spinner>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider #default="{ errors }" name="member" rules="required">
                <div v-if="selectedTeam" class="my-1">
                    <b-form-group label="Members" >
                      <template #label>
                      Members <span class="text-danger">*</span>
                    </template>
                      <b-spinner v-if="memberLoading" variant="primary" small></b-spinner>
                      <b-form-checkbox-group   v-else  v-model="selectedReviewer"  :options="formatedMemberOptions" value-field="item"
                      text-field="text" class="ml-2">
                      </b-form-checkbox-group >
                    </b-form-group>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider #default="{ errors }" name="task title"  rules="required">
 
                    <b-form-group label="Task Title">
                      <template #label>
                      Task Title <span class="text-danger">*</span>
                    </template>
                      <b-form-input v-model="task_title"></b-form-input>
                    </b-form-group>

                  <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider #default="{ errors }" name="task description" rules="required">
              
              <b-form-group label="Task Description">
                <template #label>
                      Task Description <span class="text-danger">*</span>
                    </template>
                <b-form-textarea v-model="task_description"></b-form-textarea>
              </b-form-group>

              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider #default="{ errors }" name="task-priority" rules="required">
 
              <b-form-group label="Task Priority">
                <template #label>
                      Task Priority <span class="text-danger">*</span>
                    </template>
                <v-select v-model="task_priority" :options="priorityOptions"
                :filterable="false" label="label" :reduce="item => item.value" ></v-select>
              </b-form-group>

              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
             
             
            </b-form-group>
            <!-- <div class="d-flex justify-content-center" v-if="teamOptions.page<teamOptions.total">
              <b-button variant="outline-primary" size="sm" @click="fetchReviewers({page:teamOptions.page+1})"><feather-icon icon="ChevronDownIcon"></feather-icon></b-button>
            </div> -->
            <div class="w-100 d-flex align-items-center justify-content-end">
              <b-button
              :disabled="addReviewerLoading"
                size="sm"
                variant="outline-danger"
                class="mr-50"
                @click="reviewPopoverShow=false"
              >
                Cancel
              </b-button>
              <b-button size="sm" type="submit" variant="success" :disabled="invalid||!selectedReviewer.length||addReviewerLoading">
                <b-spinner v-if="addReviewerLoading" variant="primary" small/>
                <feather-icon v-else icon="CheckIcon" class="mr-50" />
                Submit
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- <b-popover
        ref="popover"
        target="review-popover"
        triggers="manual"
        :show.sync="reviewPopoverShow"
        placement="bottom"
        container="my-container"
        @show="loadReviewers"
        custom-class="reviewer-popover"
      > -->
        <!-- <template v-slot:title>
          <div class="d-flex justify-content-between align-items-center">
            <span>Please select a reviewer.</span>
            <b-button size="sm" variant="danger" @click="reviewPopoverShow=false">Cancel</b-button>
          </div>
        </template> -->

      <!-- </b-popover> -->
      <b-popover
        ref="popover"
        target="remediation-popover"
        triggers="click"
        :show.sync="popover1Show"
        placement="bottom"
        container="my-container"
        @show="onPopover1Show"
        custom-class="wide-popover"
      >
        <template v-slot:title>
          <div class="d-flex justify-content-between align-items-center">
            <span>Please provide a reason.</span>
          </div>
        </template>

        <validation-observer ref="request-remediation-form" #default="{ invalid }">
          <b-form @submit.prevent="handleRequestRemediationSubmit">
            <b-form-group>
              <validation-provider #default="{ errors }" name="Reason" rules="required">
                <b-form-textarea
                  id="textarea"
                  v-model="remediation_reason"
                  rows="4"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="w-100 d-flex align-items-center justify-content-end">
              <b-button
                size="sm"
                variant="outline-danger"
                class="mr-50"
                @click="onPopover1Close"
              >
                Cancel
              </b-button>
              <b-button size="sm" type="submit" variant="success" :disabled="invalid">
                <feather-icon icon="CheckIcon" class="mr-50" />
                Submit
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-popover>

      <b-popover
        ref="popover"
        target="apply-noncompliance-popover"
        triggers="click"
        :show.sync="popover2Show"
        placement="bottom"
        container="my-container"
        @show="onPopover2Show"
        custom-class="wide-popover"
      >
        <template v-slot:title>
          <div class="d-flex justify-content-between align-items-center">
            <span>Please provide a reason.</span>
          </div>
        </template>

        <validation-observer ref="apply-noncompliance-form" #default="{ invalid }">
          <b-form @submit.prevent="handleApplyNoncomplianceSubmit">
            <b-form-group>
              <validation-provider #default="{ errors }" name="Reason" rules="required">
                <b-form-textarea
                  id="textarea"
                  v-model="remediation_reason"
                  rows="4"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="w-100 d-flex align-items-center justify-content-end">
              <b-button
                size="sm"
                variant="outline-danger"
                class="mr-50"
                @click="onPopover2Close"
              >
                Cancel
              </b-button>
              <b-button size="sm" type="submit" variant="success" :disabled="invalid">
                <feather-icon icon="CheckIcon" class="mr-50" />
                Submit
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-popover>

      <b-popover
        ref="popover"
        target="reject-evidences-popover"
        triggers="click"
        :show.sync="popover3Show"
        placement="bottom"
        container="my-container"
        @show="onPopover3Show"
        custom-class="wide-popover"
      >
        <template v-slot:title>
          <div class="d-flex justify-content-between align-items-center">
            <span>Please provide a reason.</span>
          </div>
        </template>

        <validation-observer ref="apply-noncompliance-form" #default="{ invalid }">
          <b-form @submit.prevent="handleRejectEvidencesSubmit">
            <b-form-group>
              <validation-provider #default="{ errors }" name="Reason" rules="required">
                <b-form-textarea
                  id="textarea"
                  v-model="remediation_reason"
                  rows="4"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="w-100 d-flex align-items-center justify-content-end">
              <b-button
                size="sm"
                variant="outline-danger"
                class="mr-50"
                @click="onPopover3Close"
              >
                Cancel
              </b-button>
              <b-button size="sm" type="submit" variant="success" :disabled="invalid">
                <feather-icon icon="CheckIcon" class="mr-50" />
                Submit
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-popover>

      <div
        class="d-flex flex-column align-items-start justify-content-start mb-2 mt-2"
        v-if="response.note && response.note != null"
      >
        <label class="font-weight-bolder">Justification:</label>
        <p class="mb-0">{{ response.note }}</p>
      </div>

      <div
        class="d-flex flex-column align-items-start justify-content-start mb-2 mt-2"
        v-if="response.observation && response.observation != null"
      >
        <label class="font-weight-bolder">Observation:</label>
        <p class="mb-0">{{ response.observation }}</p>
      </div>

      <div
        class="d-flex flex-column align-items-start justify-content-start mb-2 mt-2"
        v-if="response.recommendation && response.recommendation != null"
      >
        <label class="font-weight-bolder">Recommendation:</label>
        <p class="mb-0">{{ response.recommendation }}</p>
      </div>

      <div class="w-100 mt-1">
        <b-tabs content-class="mt-1" justified>
          <b-tab title="Evidences" ref="evidence-tab" active>
            <template v-slot:title>
              <div>
                <span
                  :class="{
                    'text-success': response.evidence_status == 3,
                    'text-danger': response.evidence_status == 4,
                  }"
                  >Evidences</span
                >
                <b-badge
                  v-if="
                    response.downloadable_evidences &&
                    response.downloadable_evidences != null &&
                    response.downloadable_evidences.length > 0
                  "
                  class="ml-25"
                  pill
                  variant="warning"
                  >{{ response.downloadable_evidences.length }}</b-badge
                >
              </div>
            </template>
            <div
              v-if="response.evidence_status == 3 || response.evidence_status == 4"
              class="w-100 py-1 px-75 d-flex flex-column align-items-center justify-content-start"
              :class="{
                'bg-light-success': response.evidence_status == 3,
                'bg-light-danger': response.evidence_status == 4,
              }"
            >
              <p class="mb-0 font-weight-bold">
                The evidences have been
                <span v-if="response.evidence_status == 3">approved</span
                ><span v-else-if="response.evidence_status == 4">rejected</span>.
              </p>
            </div>
            <div
              class="d-flex flex-column justify-content-start align-items-center w-100"
              style="max-height: 55vh; position: relative"
            >
              <table role="table" class="table table-bordered">
                <thead role="rowgroup">
                  <tr role="row">
                    <th role="columnheader" scope="col" class="header">File</th>
                    <th role="columnheader" scope="col" class="header">Uploaded By</th>
                    <th role="columnheader" scope="col" class="header">Uploaded At</th>
                  </tr>
                </thead>
                <tbody role="rowgroup">
                  <template
                    v-if="
                      response.downloadable_evidences &&
                      typeof response.downloadable_evidences != 'undefined' &&
                      response.downloadable_evidences != null &&
                      response.downloadable_evidences.length > 0
                    "
                  >
                    <tr
                      v-for="evidence in response.downloadable_evidences"
                      :key="evidence.file_id"
                      role="row"
                    >
                      <td
                        aria-colindex="1"
                        role="cell"
                        style="padding-right: 0; max-width: 240px"
                      >
                        <div class="d-flex justify-content-start w-100">
                          <b-badge
                            :key="evidence.file_id"
                            variant="light-primary"
                            style="position: relative"
                            class="p-0 px-25 d-flex flex-direction-column align-items-center justify-content-between cursor-pointer"
                            v-b-tooltip.hover
                            :title="'Click to download: ' + evidence.original_file_name"
                            @click="
                              handleDownloadButtonClick(
                                evidence.original_file_name,
                                evidence.file_id
                              )
                            "
                          >
                            <div
                              style="width: 27px"
                              class="h-100 d-flex align-items-center justify-content-center"
                            >
                              <feather-icon
                                v-if="!downloadingList.includes(evidence.file_id)"
                                icon="DownloadIcon"
                                class="mr-75"
                                size="27"
                              />
                              <b-spinner
                                v-else
                                class="ml-0 mr-75"
                                variant="primary"
                                small
                              />
                            </div>

                            <p class="truncate text-left mb-0">
                              {{ evidence.original_file_name }}
                            </p>
                          </b-badge>
                        </div>
                      </td>
                      <td aria-colindex="2" role="cell">
                        <span
                          class="mb-0 font-weight-bold"
                          v-if="evidence.uploaded_user && evidence.uploaded_user != null"
                        >
                          {{ evidence.uploaded_user.firstname }}
                          {{ evidence.uploaded_user.lastname }}
                        </span>
                      </td>
                      <td aria-colindex="3" role="cell">
                        {{ evidence.uploaded_at | moment }}
                      </td>
                    </tr>
  

                  </template>
                  <template v-else>
                    <empty-table-section title="No Evidences">
                      <template #content>
                        <p class="font-weight-bold text-center">
                          It seems like there are no Evidences at the moment.
                        </p>
                      </template>
                    </empty-table-section>
                  </template>
                </tbody>
              </table>
              </div>
                <div class="d-flex justify-content-end"  v-if="
                      response.downloadable_evidences &&
                      typeof response.downloadable_evidences != 'undefined' &&
                      response.downloadable_evidences != null &&
                      response.downloadable_evidences.length > 0
                    ">
    
                  <b-button  class="mr-1 " size="sm" variant="primary"
                  v-if="response.evidence_status !== 3&&response.evidence_status !== 4"
                    popover-class="reviewer-popover"
                    @click="openReviewerPopup"
                      ><feather-icon icon="PlusIcon" class="mr-50" /><span class="align-middle"
                        >Add Reviewers</span
                      ></b-button
                    >
                </div>
            <div
              v-if="
                response.evidence_status &&
                response.evidence_status != null &&
                response.evidence_status == 2 &&
                disableActions === false
              "
              class="w-100 d-flex flex-column align-items-center justify-content-start mt-0"
            >
              <div class="w-100 d-flex align-items-center justify-content-center">
                <p class="mb-75 text-center">
                  Choose any of the following actions to
                  <span class="text-success font-weight-bold">Approve</span> or
                  <span class="text-danger font-weight-bold">Reject</span> the evidences
                  uploaded by the assigned users.
                </p>
              </div>

              <div class="w-100 d-flex align-items-center justify-content-between">
                <b-button
                  size="md"
                  block
                  variant="success"
                  class="m-0 mr-1"
                  @click="handleEvidenceApproveClick"
                  ><feather-icon icon="CheckCircleIcon" class="mr-50" /><span
                    class="align-middle"
                    >Approve Evidences</span
                  ></b-button
                >
                <b-button
                  size="md"
                  variant="danger"
                  block
                  class="m-0 ml-1"
                  id="reject-evidences-popover"
                  ><feather-icon icon="XCircleIcon" class="mr-50" /><span
                    class="align-middle"
                    >Reject</span
                  ></b-button
                >
              </div>
            </div>
          </b-tab>
          <b-tab title="Discussions">
            <template v-slot:title>
              <div>
                Discussions
                <b-badge
                  v-if="comments.length > 0"
                  class="ml-25"
                  pill
                  variant="warning"
                  >{{ comments.length }}</b-badge
                >
              </div>
            </template>
            <div class="w-100 h-auto">
              <comments-section
                :comments="comments"
                :showCommentLoading="showCommentLoading"
                :disableCreation="disableActions"
                :newCommentCallback="
                  (comment) => {
                    return addCommentAndSetData(
                      $route.params.assessmentId,
                      response.questionnaire_id,
                      response._id,
                      { comment }
                    );
                  }
                "
              />
            </div>
          </b-tab>
          <b-tab title="Discussions">
            <template v-slot:title>
              <div>
                Reviews
                <b-badge
                  v-if="reviews.total > 0"
                  class="ml-25"
                  pill
                  variant="warning"
                  >{{ reviews.total }}</b-badge
                >
              </div>
            </template>
            <div class="w-100 h-auto">
              <reviews-section
                :reviews="reviews.data"
                :showCommentLoading="showCommentLoading"
                :disableCreation="disableActions"
                :loadMore="loadMoreReviews"
                :loading="taskLoading"
                :newCommentCallback="
                  (comment) => {
                    return addCommentAndSetData(
                      $route.params.assessmentId,
                      response.questionnaire_id,
                      response._id,
                      { comment }
                    );
                  }
                "
              />
              <div class="d-flex justify-content-end" v-if="
                      response.downloadable_evidences &&
                      typeof response.downloadable_evidences != 'undefined' &&
                      response.downloadable_evidences != null &&
                      response.downloadable_evidences.length > 0"
                    >
    
                  <b-button  class="mr-1 " size="sm" variant="primary"
                  v-if="response.evidence_status !== 3&&response.evidence_status !== 4"
                      @click="openReviewerPopup" popover-class="reviewer-popover"
                      ><feather-icon icon="PlusIcon" class="mr-50" /><span class="align-middle"
                        >Add Reviewers</span
                      ></b-button
                    >
                </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <div >

      <!-- <b-button style="opacity: 0;height: 0px;" class="d-flex align-items-end ml-auto"
                      popover-class="reviewer-popover" id="review-popover" 
                    
                        >Add reviewer</b-button
                      > -->
    </div>
              
  </b-overlay>
</template>

<script>
import {
  BTabs,
  BTab,
  BBadge,
  BSpinner,
  VBTooltip,
  BButton,
  BPopover,
  BFormTextarea,
  BFormRadioGroup,
  BFormGroup,
  BForm,
  BOverlay,
  BFormRadio,
  BFormCheckboxGroup,
  BFormInput,
  BModal,
} from "bootstrap-vue";
import EmptyTableSection from "../../../../components/EmptyTableSection.vue";
import CommentsSection from "../../../../components/CommentsSection.vue";
import ReviewsSection from "../../../../components/ReviewsSection.vue";
import ResponseMixins from "../../../../mixins/ResponseMixins";
import CommentMixins from "../../../../mixins/CommentMixins";
import TeamsMixins from "../../../../mixins/TeamsMixins";
import moment from "moment";
import UtilsMixins from "../../../../mixins/UtilsMixins";
import QuestionnaireMixins from "../../../../mixins/QuestionnaireMixins";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ThirdPartyRisksRAMixins from "../../../../mixins/ThirdPartyRisksRAMixins";
import VSelect from "vue-select";
import TaskMixins from "../../../../mixins/TaskMixins";
export default {
  components: {
    BTabs,
    BTab,
    BBadge,
    EmptyTableSection,
    CommentsSection,
    ReviewsSection,
    moment,
    BSpinner,
    BButton,
    BPopover,
    BFormTextarea,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckboxGroup,
    VSelect,
    BFormInput,
    BModal
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mixins: [
    ResponseMixins,
    CommentMixins,
    UtilsMixins,
    QuestionnaireMixins,
    ThirdPartyRisksRAMixins,
    TeamsMixins,
    TaskMixins
  ],
  props: {
    response: {
      type: String,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
    closeAction:{
      type: Function,
      default:()=>{}
    },
    assessmentStatus: {
      type: Number,
      required: true,
    },
    disableActions: {
      type: Boolean,
      required: false,
      required: false,
    },
    assessmentId: {
      type: String,
      required: false,
    }
  },

  data() {
    return {
      showOverlay: false,
      popover1Show: false,
      popover2Show: false,
      popover3Show: false,
      reviewPopoverShow:false,
      selectedTeam:null,
      teamLoading:false,
      memberLoading:false,
      addReviewerLoading:false,
      taskLoading:false,
      initialReviewers:[],
      reviews:{},
      selectedReviewer:[],
      priorityOptions:[
        {
          label:"Low",
          value:1
        },
        {
          label:"Medium",
          value:2
        },
        {
          label:"High",
          value:3
        },
        {
          label:"Critical",
          value:4
        },

      ],
      teamOptions:{
        options:
        [
        // Add more options as needed
        ],
        page:0,
        total:0
      } ,
      memberOptions:{
        options:[]
      },
      task_title:null, task_description:null, task_priority:null,
      comments: [],
      downloadingList: [],
      showCommentLoading: false,
      remediation_reason: null,
    };
  },
  computed: {
    formatedMemberOptions(){
      return this.memberOptions.options.map(option=>{
        return {
          ...option,
          disabled: this.initialReviewers.includes(option.item),
        };
      })
    }
  },

  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },

  mounted() {
    this.load();
    // this.initialReviewers=this.response.tasked_users.map(item=>item._id)
    this.selectedReviewer=this.initialReviewers
  },

  methods: {
    load() {
      console.log("Selected response", this.response);
      this.getCommentsAndSetData(
        this.$route.params.assessmentId,
        this.response.questionnaire_id,
        this.response._id
      );
      this.setTasks({reload:true})
      // this.getAssessmentResponsesDetailsAndSetData(
      //   this.$route.params.assessmentId,
      //   this.questionnaireId,
      //   this.responseId
      // );
      
    },
    async setTasks(params){
      try {
        if(params?.reload){
          this.reviews={}
        }
        this.taskLoading=true
        const res=await this.getTasks({reference_id:this.response._id,page:this.reviews?.current_page?this.reviews.current_page+1:1})
        this.taskLoading=false
        if(Object.keys(this.reviews).length === 0 && this.reviews.constructor === Object){
          this.reviews=res.data.data
        }else
          this.reviews={...res.data.data,data:[...this.reviews?.data,...res.data.data.data]}
      } catch (error) {
        console.log(error)
        this.taskLoading=false
        this.handleError(error)
      }
    },

    getAssessmentResponsesDetailsAndSetData(assessmentId, questionnaireId, responseId) {
      this.showOverlay = true;
      this.getAssessmentResponsesDetails(assessmentId, questionnaireId, responseId)
        .then((res) => {
          console.log(res);
          this.response = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleRejectEvidencesSubmit() {
      const data = {
        reject_evidence: 1,
        comment: this.remediation_reason,
      };
      this.updateQuestionAndSetData(data);
      this.onPopover3Close();
    },

    onPopover3Show() {
      this.remediation_reason = null;
      this.popover3Show = true;
    },

    onPopover3Close() {
      this.popover3Show = false;
    },
    openReviewerPopup(){

      this.$refs['evidence-tab'].activate()
      this.reviewPopoverShow=!this.reviewPopoverShow
    },
    async loadMoreReviews(){
      if(this.reviews.current_page)
      if(this.reviews.current_page<this.reviews.last_page){
        this.setTasks()
      }
    },
    handleRequestRemediationSubmit() {
      const data = {
        request_remediation: 1,
        comment: this.remediation_reason,
      };
      this.updateQuestionAndSetData(data);
      this.onPopover1Close();
    },

    onPopover1Show() {
      this.remediation_reason = null;
      this.popover1Show = true;
    },

    onPopover1Close() {
      this.popover1Show = false;
    },

    handleApplyNoncomplianceSubmit() {
      const data = {
        is_response_rejected: 1,
        comment: this.remediation_reason,
      };
      this.updateQuestionAndSetData(data);
      this.onPopover2Close();
    },

    onPopover2Show() {
      this.remediation_reason = null;
      this.popover2Show = true;
    },

    onPopover2Close() {
      this.popover2Show = false;
    },

    // handleApplyNonComplianceClick() {
    //   this.$bvModal
    //     .msgBoxConfirm("Please confirm that you want to Apply Non-compliance?.", {
    //       title: "Warning",
    //       size: "sm",
    //       okVariant: "primary",
    //       okTitle: "Confirm",
    //       cancelTitle: "No",
    //       cancelVariant: "outline-secondary",
    //       hideHeaderClose: false,
    //       centered: true,
    //       headerBgVariant: "light-warning",
    //     })
    //     .then((value) => {
    //       if (value) {
    //         this.updateQuestionAndSetData({ is_response_rejected: 1 });
    //       }
    //     });
    // },

    handleEvidenceApproveClick() {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to Approve these evidences?.", {
          title: "Warning",
          size: "sm",
          okVariant: "success",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
          headerBgVariant: "light-warning",
        })
        .then((value) => {
          if (value) {
            this.updateQuestionAndSetData({ approve_evidence: 1 });
          }
        });
    },

    // handleEvidenceRejectClick() {
    //   this.$bvModal
    //     .msgBoxConfirm(
    //       "Please confirm that you want to Reject these evidences?.",
    //       {
    //         title: "Warning",
    //         size: "sm",
    //         okVariant: "primary",
    //         okTitle: "Confirm",
    //         cancelTitle: "No",
    //         cancelVariant: "outline-secondary",
    //         hideHeaderClose: false,
    //         centered: true,
    //         headerBgVariant: "light-warning",
    //       }
    //     )
    //     .then((value) => {
    //       if (value) {
    //         this.updateQuestionAndSetData({ reject_evidence: 1 });
    //       }
    //     });
    // },

    updateQuestionAndSetData(data) {
      this.showOverlay = true;
      this.updateQuestionV2(
        data,
        this.$route.params.assessmentId||this.assessmentId,
        this.response.questionnaire_id,
        this.response._id
      )
        .then((res) => {
          this.handleResponse(res);
          this.reloadParent();
          this.closeAction()
          this.load();
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getResponseValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 2:
            return "No";
          case 3:
            return "Not Applicable";
          default:
            return "Not Given";
        }
      }
      return "Not Given";
    },

    getResponseVariant(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "success";
          case 2:
            return "warning";
          case 3:
            return "secondary";
          default:
            return "dark";
        }
      }
      return "dark";
    },

    getComplianceValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 2:
            return "No";
          case 3:
            return "Not Applicable";
          default:
            return "Unknown";
        }
      }
      return "Unknown";
    },

    handleDownloadButtonClick(fileName, id) {
      if (!this.downloadingList.includes(id)) {
        this.downloadingList.push(id);
        const options = {
          method: "GET",
          responseType: "blob",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/file-uploads/${id}/download`,
        };
        this.debounceFunction(() => {
          this.$http(options)
            .then((res) => {
              var blob = new Blob([res.data]);
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, fileName);
              } else {
                var a = window.document.createElement("a");
                a.href = window.URL.createObjectURL(blob, {
                  type: "text/plain",
                });
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }

              this.downloadingList = this.downloadingList.filter(
                (item_id) => item_id !== id
              );
            })
            .catch((err) => {
              this.handleError(err);
              console.log(err);
            });
        });
      }
    },

    getCommentsAndSetData(assessmentId, questionnaireId, questionId) {
      this.showCommentLoading = true;
      this.getComments(assessmentId, questionnaireId, questionId)
        .then((res) => {
          console.log("Comments", res);
          this.comments = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showCommentLoading = false;
        });
    },

    addCommentAndSetData(assessmentId, questionnaireId, questionId, data) {
      this.showCommentLoading = true;
      return new Promise((resolve, reject) => {
        this.addNewComment(assessmentId, questionnaireId, questionId, data)
          .then((res) => {
            this.handleResponse(res);
            this.load();
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
            reject(err);
          })
          .finally(() => {
            this.showCommentLoading = false;
          });
      });
    },
    loadReviewers(){
      this.selectedTeam=null,
      this.teamLoading=false,
      this.memberLoading=false,
      this.selectedReviewer=this.initialReviewers,
      this.task_title=null,
      this.task_description=null,
      this.task_priority=null
      this.teamOptions={
        options:
        [
        // Add more options as needed
        ],
        page:0,
        total:0
      } 
      this.memberOptions={
        options:[]
      }
      this.fetchReviewers()
    },
    async fetchReviewers(params){
      this.teamLoading=true
     const teamResponse= await this.getTeams(params)
      this.teamLoading=false
     this.teamOptions={
      options:[...teamResponse.data.data.map(item=>{
        return {
          text:item.name,
          value:item._id
        }
      })],
      page:teamResponse.data.current_page,
      total:teamResponse.data.total_pages
     }
    },
    async fetchMembers(){
      this.memberLoading=true
      const memberResponse=await this.getTeamDetails(this.selectedTeam.value)
      this.memberLoading=false
      this.memberOptions.options=memberResponse.data.data.users.map(item=>{
        return {
          text:`${item.firstname?item.firstname:''} ${item.lastname?item.lastname:''}`,
          item:item.user_id
        }
      })
    },
    async addReviewer(){
      
      // const invalid = !this.$refs.reviewerForm.$error;
      // if(invalid)
      // return
      const body={task_title:this.task_title,task_description:this.task_description,task_priority:this.task_priority}
      const bodyFormData = new FormData();
      // bodyFormData.append("taskedUsers", this.selectedReviewer);
      this.selectedReviewer.forEach((reviewer) => {
        bodyFormData.append("taskedUsers[]", reviewer);
      });
      Object.keys(body).forEach(item=>{
        bodyFormData.append(`${item}`, body[`${item}`]);
      })
      const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/tpr/assessments/${this.response.questionnaire.assessment_id}/questionnaires/${this.response.questionnaire_id}/responses/${this.response._id}`,
          data:bodyFormData
        };
      try {
        this.addReviewerLoading=true
        const response=await this.$http(options)
        this.reviewPopoverShow=false
        this.handleResponse(response)
        this.reloadParent();
        this.task_title=null,
        this.task_description=null,
        this.task_priority=null
        this.load()
        this.addReviewerLoading=false
        
      } catch (error) {
        this.addReviewerLoading=false
        this.reviewPopoverShow=false
        this.handleError(error)
      }
      
    },
    fetchOptions(search){
      this.fetchReviewers({search:search})
    }
  },
  watch:{
    selectedTeam:{
      handler(newVal) {
        // if (newVal && newVal.id) {
          this.fetchMembers();
        // }
      },
      deep: true
    },
    // selectedReviewer(){
    //   this.addReviewer()
    // }
  }
};
</script>

<style lang="scss" scoped>
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.8rem;
}
.truncate{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 185px;
}
</style>
<style lang="css" >
.wide-popover {
  width: 500px;
}
.reviewer-popover{
 min-width:500px;
}
.reviewer-popover .arrow{
  display:none;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
