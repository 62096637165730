<template>
  <b-overlay opacity="0.17" blur="1rem" :show="showOverlay" rounded="md" variant="secondary">
    <b-sidebar lazy id="sidebar-backdrop" sidebar-class="sidebar-lg" bg-variant="white" backdrop right no-header shadow>
      <add-user-to-vendor-form :vendorId="this.$route.params.id" :reloadParent="load" :closeSidebar="closeSidebar" />
    </b-sidebar>

    <!-- <b-sidebar
      lazy
      id="sidebar-questionnaire"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <add-questionnaire-to-vendor-form
        :vendorId="this.$route.params.id"
        :reloadParent="load"
        :closeSidebar="closeQuestionnaireSidebar"
      />
    </b-sidebar> -->

    <b-sidebar lazy id="sidebar-tpr-assessment" sidebar-class="sidebar-lg" bg-variant="white" backdrop right no-header
      shadow>
      <add-assessment-to-vendor-form :vendorId="this.$route.params.id" :reloadParent="load"
        :closeSidebar="closeAssessmentSidebar" />
    </b-sidebar>
    <div class="card">
      <div class="card-header" v-if="vendor">
        <div class="d-flex flex-column align-items-start justify-content-start">
          <h2 class="mb-1 font-weight-bold">{{ vendor.name }}</h2>
          <p class="card-subtitle text-muted" v-if="vendor.created_at && vendor.created_at !== null">
            Created at
            <span class="text-bold">{{ vendor.created_at | moment }} </span>
          </p>
        </div>
      </div>
      <b-tabs content-class="mt-2" align="start" nav-wrapper-class="ml-2">
        <b-tab title="Risks" :disabled="isUserVendor == true">
          <risk-listing :vendorId="$route.params.id"/>
          <!-- <div class="card" v-if="risks !== null"> -->

            <!-- <div class="pb-25 pt-0 w-100 row">
            
               
            </div> -->
          <!-- </div> -->
        </b-tab>
        <b-tab title="Assessments" :active="isUserVendor == true">
          <div class="card" v-if="assessments !== null">
            <!-- <div class="card-header">
              <b-button
                  v-if="!isUserVendor"
                  @click="handleNewAssessmentClick"
                  variant="primary"
                  ><feather-icon class="mr-75" icon="PlusIcon" />New
                  Assessment</b-button
                >
            </div> -->
            <div class="pb-25 pt-0 w-100 row">
              <div class="app-fixed-search col-md-8 col-sm-12">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                              <feather-icon v-if="assessmentFilters.search == null || assessmentFilters.search == ''
              " icon="SearchIcon" class="text-muted" />
                              <feather-icon @click="() => {
                assessmentFilters.search = null;
              }
    " v-else icon="XIcon" class="text-danger cursor-pointer" />
                  </b-input-group-prepend>
                  <b-form-input v-model="assessmentFilters.search" placeholder="Search..." debounce="500" size="md" />
                </b-input-group>
              </div>
              <div class="col-sm-4">
                <div class="w-100 d-flex align-items-center justify-content-end"></div>
              </div>
            </div>
            <div class="table-responsive">
              <table role="table" class="table table-hover">
                <thead role="rowgroup">
                  <tr role="row">
                    <th role="columnheader" scope="col">Name</th>
                    <th role="columnheader" scope="col">Description</th>
                    <th role="columnheader" scope="col" class="text-center">Status</th>
                    <!-- <th role="columnheader" scope="col" class="text-center">
                      Questionnaires
                    </th> -->
                    <th role="columnheader" scope="col" class="text-center">Progress</th>
                    <!-- <th role="columnheader" scope="col" class="text-center">Action</th> -->
                  </tr>
                </thead>
                <tbody role="rowgroup">
                  <template v-if="assessments && assessments != null && assessments.length > 0">
                    <tr v-for="assessment in assessments" :key="assessment._id" role="row" class="cursor-pointer"
                      @click="handleAssessmentClick(assessment._id)">
                      <td role="cell" style="max-width: 20vw">
                        <span class="font-weight-bold">{{ assessment.title }}</span>
                      </td>

                      <td role="cell" style="max-width: 20vw">
                        {{ assessment.description }}
                      </td>

                      <td role="cell">
                        <div class="d-flex justify-content-center">
                          <b-badge :variant="getStatusColor(assessment.status)">{{
    getStatusNumber(assessment.status)
  }}</b-badge>
                        </div>
                      </td>

                      <!-- <td role="cell">
                        <div class="d-flex justify-content-center">
                          <b-avatar
                            :text="assessment.total_questionnaires.toString()"
                            variant="light-primary"
                          />
                        </div>
                      </td> -->

                      <td role="cell">
                        <div class="d-flex justify-content-center">
                          <progress-badge :min="0" :max="100" title="Progress" :value="assessment.completion"
                            :colorsReversed="true" />
                          <!-- <donut-bar
                          :percentage="Number(assessment.completion.toFixed(2))"
                          :displayValue="assessment.completion + '%'"
                          size="xsm"
                          :uniqueId="assessment._id + 2"
                          :colorsReversed="true"
                        /> -->
                        </div>
                      </td>
                      <!-- <td role="cell">
                        <div class="d-flex justify-content-center">
                          <b-button @click.stop="handleEditClick(assessment._id)" variant="info"
                            class="btn-icon rounded-circle">
                            <feather-icon icon="EditIcon" />
                          </b-button>
                        </div>
                      </td> -->
                    </tr>
                  </template>
                  <template v-else>
                    <empty-table-section title="Assessments Empty">
                      <template #content>
                        <p class="text-center">
                          It seems there are no assessments at the moment.
                        </p>
                      </template>
                    </empty-table-section>
                  </template>
                </tbody>
              </table>

              <div class="w-100 d-flex align-items-center justify-content-center"
                v-if="assessments && assessments != null && assessments.length > 0">
                <b-pagination class="mt-2" v-model="assessmentFilters.page" :per-page="assessmentFilters.perPage"
                  :total-rows="assessmentFilters.totalPages * assessmentFilters.perPage"
                  aria-controls="my-table"></b-pagination>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Users" :disabled="isUserVendor == true">
          <div class="card" v-if="vendor">
            <div class="card-header pb-75">
              <div class="d-flex align-items-center justify-content-between w-100">
                <div class="d-flex flex-column align-items-start justify-content-start">
                  <h3 class="mb-25 font-weight-bolder">Assigned Users</h3>
                  <p class="mb-0">Manage assigned users.</p>

                </div>

                <div class="d-flex flex-column align-items-center justify-content-between">
                  <b-button size="sm" @click="handleNewUserClick" variant="success"><feather-icon class="mr-50"
                      icon="PlusIcon" />Assign New
                    User</b-button>
                </div>
              </div>
             
            </div>
            <div class="app-fixed-search col-md-8 col-sm-12">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon v-if="vendorUsersFilters.search == null || vendorUsersFilters.search == ''" icon="SearchIcon"
                      class="text-muted" />
                    <feather-icon @click="() => {
      policyFilters.search = null;
    }
    " v-else icon="XIcon" class="text-danger cursor-pointer" />
                  </b-input-group-prepend>
                  <b-form-input v-model="vendorUsersFilters.search" placeholder="Search..." debounce="500" size="md" />
                </b-input-group>
              </div>
            <!-- <div class="card-header">
              <div
                class="d-flex flex-column align-items-end justify-content-between"
              >
                <b-button @click="handleNewUserClick" variant="success"
                  ><feather-icon class="mr-75" icon="PlusIcon" />Add User to
                  Third Party</b-button
                >
              </div>
            </div> -->
            <table role="table" class="table">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col" class="text-start">#</th>
                  <th role="columnheader" scope="col" class="text-start">Name</th>
                  <th role="columnheader" scope="col" class="text-start">
                    Invite Date
                  </th>
                  <th role="columnheader" scope="col" class="text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <tr v-for="(user,index) in vendorUsers" :key="user._id" role="row" class="cursor-pointer">
                  <td>{{(vendorUsersFilters.page - 1) * vendorUsersFilters.perPage + index + 1  }}</td>
                  <td aria-colindex="2" role="cell" style="max-width: 20vw">
                    <span class="font-weight-bold">
                      {{ user.firstname }} {{ user.lastname }}
                    </span>
                  </td>

                  <td aria-colindex="2" role="cell">
                    <div class="d-flex justify-content-start">
                      <p class="text-start font-weight-bold mb-0">
                        {{ user.created_at | moment }} <b-badge variant="success" class="ml-50" v-if="user.last_logined_at">Joined</b-badge>
                      </p>
                    </div>
                  </td>

                  <td aria-colindex="2" role="cell">
                    <div class="d-flex flex-column align-items-center justify-content-evenly">
                      <b-button @click="reInvite(user)" variant="primary" size="sm" v-if="user.last_logined_at == null">
                        <!-- <feather-icon icon="XIcon" /> -->
                        <span class="ml-75">Reinvite</span></b-button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="card-body" v-if="vendorUsers !== null">
              <div class="d-flex align-items-center justify-content-center w-100 pt-3">
                <b-pagination v-model="vendorUsersFilters.page" :per-page="vendorUsersFilters.perPage"
                  :total-rows="vendorUsersFilters.totalPages * vendorUsersFilters.perPage"
                  aria-controls="my-table"></b-pagination>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Policies" :disabled="isUserVendor == true">
          <div class="card" v-if="assessments !== null">
            <div class="pb-25 pt-0 w-100 row">
              <div class="app-fixed-search col-md-8 col-sm-12">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon v-if="policyFilters.search == null || policyFilters.search == ''" icon="SearchIcon"
                      class="text-muted" />
                    <feather-icon @click="() => {
      policyFilters.search = null;
    }
    " v-else icon="XIcon" class="text-danger cursor-pointer" />
                  </b-input-group-prepend>
                  <b-form-input v-model="policyFilters.search" placeholder="Search..." debounce="500" size="md" />
                </b-input-group>
              </div>
              <div class="col-sm-4">
                <div class="w-100 d-flex align-items-center justify-content-end"></div>
              </div>
            </div>
            <div class="table-responsive">
              <table role="table" class="table table-hover">
                <thead role="rowgroup">
                  <tr role="row">
                    <th role="columnheader" scope="col">#</th>
                    <th role="columnheader" scope="col">Name</th>
                    <th role="columnheader" scope="col">Description</th>
                  </tr>
                </thead>
                <tbody role="rowgroup">
                  <template v-if="vendor.policies &&
    vendor.policies != null &&
    vendor.policies.length > 0
    ">
                    <tr v-for="(policy, index) in filteredPolicies" :key="policy._id" role="row" 
                     >
                      <th role="cell">
                        {{ index + 1 }}
                      </th>
                      <td role="cell" style="max-width: 20vw">
                        <span class="font-weight-bold">{{ policy.title }}</span>
                      </td>

                      <td role="cell" style="max-width: 20vw">
                        {{ policy.description }}
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <empty-table-section title="Policies Empty">
                      <template #content>
                        <p class="text-center">
                          It seems there are no policies at the moment.
                        </p>
                      </template>
                    </empty-table-section>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </b-tab>

      </b-tabs>
    </div>
  </b-overlay>
</template>

<script>
import ResponseMixins from "../../mixins/ResponseMixins";
import ThirdPartyRisksMixins from "../../mixins/ThirdPartyRisksMixins";
import {
  BOverlay,
  BSidebar,
  BButton,
  BAvatar,
  BPagination,
  BTabs,
  BTab,
  BBadge,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import UtilsMixins from "../../mixins/UtilsMixins";
import AddUserToVendorForm from "./components/AddUserToVendorForm.vue";
import AddQuestionnaireToVendorForm from "./components/AddQuestionnaireToVendorForm.vue";
import AssessmentResponsesMixins from "@/mixins/AssessmentResponsesMixins";
import DonutBar from "@/components/DonutBar.vue";
import TaskMixins from "../../mixins/TaskMixins";
import AddAssessmentToVendorForm from "./components/AddAssessmentToVendorForm.vue";
import UserMixins from "../../mixins/UserMixins";
import moment from "moment";
import ProgressBadge from "../../components/ProgressBadge.vue";
import EmptyTableSection from "../../components/EmptyTableSection.vue";
import RiskListing from "./components/RiskListing.vue";

export default {
  components: {
    BOverlay,
    BSidebar,
    BButton,
    BAvatar,
    AddUserToVendorForm,
    AddQuestionnaireToVendorForm,
    AddAssessmentToVendorForm,
    DonutBar,
    BPagination,
    BTabs,
    BTab,
    BBadge,
    ProgressBadge,
    EmptyTableSection,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    vSelect,
    BFormGroup,
    RiskListing
  },
  data() {
    return {
      showOverlay: false,
      vendor: null,
      importedQuestionnaire: null,
      assessments: null,
      risks: [],
      riskfilters:{
        search: null, page: 1, status: null, evidence_status: null, risk_value: null, assessment_id: null
      },
      riskValueFiltersOptions: [
        { text: "Low", value: 1 },
        { text: "Medium", value: 2 },
        { text: "High", value: 3 },
      ],
      statusFiltersOptions: [
        { text: "Yes", value: 1 },
        { text: "No", value: 2 },
        { text: "Not Applicable", value: 3 },
      ],
      evidenceStatusFiltersOptions: [
        { text: "Requested", value: 1 },
        { text: "Uploaded", value: 2 },
        { text: "Approved", value: 3 },
        { text: "Rejected", value: 4 },
      ],
      assessmentFiltersOptions: [],
      isUserVendor: false,
      vendorUsers: null,
      vendorUsersFilters: {
        page: 1,
        perPage: 10,
        totalPages: 1,
        search:null
      },
      assessmentFilters: {
        search: null,
        page: 1,
        perPage: 10,
        totalPages: 1,
      },
      policyFilters: {
        search: null,
      },
    };
  },

  mixins: [ThirdPartyRisksMixins, ResponseMixins, UtilsMixins, TaskMixins, UserMixins,AssessmentResponsesMixins],

  watch: {
    vendorUsersFilters: {
      handler: function (newValue) {
        this.getVendorUsersAndSetData(newValue, this.$route.params.id);
      },
      deep: true,
    },
    riskfilters: {
      handler: function (newValue) {
        this.getRisksOfVendorAndSetData(newValue, this.$route.params.id);
      },
      deep: true,
    },
    assessmentFilters: {
      handler: function (newValue) {
        this.getAssessmentsOfVendorAndSetData(newValue, this.$route.params.id);
      },
      deep: true,
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },

  computed: {
    filteredPolicies() {
      if (
        this.policyFilters.search === "" ||
        this.policyFilters.search == null ||
        typeof this.policyFilters.search == "undefined"
      ) {
        return this.vendor.policies;
      }

      return this.vendor.policies.filter((p) => {
        if (
          p.title.includes(this.policyFilters.search) ||
          p.description.includes(this.policyFilters.search)
        ) {
          return true;
        } else {
          return false;
        }
      });
    },
  },

  mounted() {
    this.load();
  },
  methods: {
    getResponseVariant(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "success";
          case 2:
            return "danger";
          case 3:
            return "light-dark";
          default:
            return "light-dark";
        }
      }
    },
    getResponseValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 2:
            return "No";
          case 3:
            return "Not Applicable";
          default:
            return "Empty";
        }
      }
    },
    resetRiskFilters(){
      this.riskfilters={
        search: null, page: 1, status: null, evidence_status: null, risk_value: null, assessment_id: null
      }
    },
    getComplianceValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 2:
            return "No";
          case 3:
            return "Not Applicable";
          default:
            return "Empty";
        }
      }
      return "Empty";
    },
    handleEditClick(assessmentId) {
      this.$router.push(`/thirdpartyrisksRA/assessments/edit/${assessmentId}`);
    },
    reInvite(user) {
      this.reInviteVendorUser(user).then((res) => {
        this.handleResponse(res)
      })
        .catch((err) => {
          this.handleError(err)
        })
    },
    load() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        this.isUserVendor = true;
      } else {
        this.isUserVendor = false;
      }

      this.getVendorDetailsAndSetData(this.$route.params.id);
      this.getVendorUsersAndSetData(this.vendorUsersFilters, this.$route.params.id);
       this.getAssessmentsOfVendorAndSetData(
        this.assessmentFilters,
        this.$route.params.id
      )
      this.getAssessmentsOfVendor({},this.$route.params.id )
        .then((res) => {
          
          this.assessmentFiltersOptions = res.data.data.data.map((p) => {

              return { text: p.title, value: p._id };
            });
;
        })
        // this.assessmentFiltersOptions= this.assessments.map((p) => {
        //   console.log('p',p)
        //       return { text: p.title, value: p._id };
        //     });

      this.getRisksOfVendorAndSetData(this.riskfilters,this.$route.params.id)
    },

    getStatusNumber(num) {
      num = parseInt(num);
      switch (num) {
        case 1:
          return "In Progress";
        case 2:
          return "Completed";
        default:
          return "Unknown";
      }
    },
    getStatusColor(num) {
      switch (num) {
        case 1:
          return "info";
        case 2:
          return "success";
        default:
          return "info";
      }
    },

    getVendorUsersAndSetData(params, vendorId) {
      this.showOverlay = true;
      this.getVendorUsers(params, vendorId)
        .then((res) => {
          this.vendorUsers = res.data.data.data;
          this.vendorUsersFilters.page = res.data.data.current_page;
          this.vendorUsersFilters.perPage = res.data.data.per_page;
          this.vendorUsersFilters.totalPages = res.data.data.last_page;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleAssessmentClick(id) {
      this.$router.push(
        `/thirdpartyrisks/${this.$route.params.id}/details/assessment/${id}`
      );
    },

    handleNewUserClick() {
      this.openSidebar();
    },
    handleUserRemoveClick(user) {
      this.showOverlay = true;
      this.setUserActiveStatus(user.user_id, 2)
        .then((res) => {
          this.handleResponse(res);
          this.load();
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getAssessmentsOfVendorAndSetData(filters, vendorId) {
      this.showOverlay = true;
      this.getAssessmentsOfVendor(filters, vendorId)
        .then((res) => {
          this.assessments = res.data.data.data;

          console.log('assesment0',this.assessments)
          this.assessmentFilters.page = res.data.data.current_page;
          this.assessmentFilters.perPage = res.data.data.per_page;
          this.assessmentFilters.totalPages = res.data.data.last_page;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
          console.log('finally')
          return Promise.resolve()
        });
    },
    getRisksOfVendorAndSetData(filter,vendorId) {
      this.showOverlay = true;
      this.getAssessmentResponses({...filter,vendor_id:vendorId})
        .then((res) => {
          this.risks = res.data.data.data;
          console.log('risks',this.risks)
          
          // this.assessmentFilters.page = res.data.data.current_page;
          // this.assessmentFilters.perPage = res.data.data.per_page;
          // this.assessmentFilters.totalPages = res.data.data.last_page;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
          
        });
    },

    handleNewAssessmentClick() {
      this.openAssessmentSidebar();
    },

    handleAddQuestionnaireClick() {
      this.openQuestionnaireSidebar();
    },

    getVendorDetailsAndSetData(id) {
      this.showOverlay = true;
      this.getVendorDetails(id)
        .then((res) => {
          this.vendor = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getImportedQuestionnaireAndSetData(vendorId) {
      this.showOverlay = true;
      this.getQuestionnaire({}, vendorId)
        .then((res) => {
          this.importedQuestionnaire = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    closeAssessmentSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-tpr-assessment");
    },
    openAssessmentSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-tpr-assessment");
      });
    },

    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      });
    },

    closeQuestionnaireSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-questionnaire");
    },
    openQuestionnaireSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-questionnaire");
      });
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}
</style>

<style lang="scss" scoped>
.table th {
  text-transform: none;
}

.filters_section {
  background-color: #f2f0f7;
}

.dark-layout {
  .filters_section {
    background-color: #161d31;
  }
}
</style>

<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>
