<template>
    <tr style="height: 130px" class="bg-transparent">
      <td colspan="12">
        <div
          class="w-auto d-flex flex-column align-items-center justify-content-center"
          style="padding: 3rem 0;"
        >
          <feather-icon icon="DatabaseIcon" class="mb-1 text-secondary" size="32" />
          <h4 class="font-weight-bolder">{{ title }}</h4>
          <slot name="content"> </slot>
        </div>
      </td>
    </tr>
  </template>
  
  <script>
  import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
  export default {
    components: { FeatherIcon },
    props: {
      title: {
        type: String,
        required: false,
        default: "Empty Content",
      },
    },
  };
  </script>
  
  <style></style>
    
