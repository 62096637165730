export default {
    methods:{
        getTeams(params){
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/teams`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },
        addTeam(obj,selectedusers) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                if (obj.name !== null) {
                    bodyFormData.append("name", obj.name);
                }
                if (obj.description !== null) {
                    bodyFormData.append("description", obj.description);
                }
                if (selectedusers !== null) {
                    // Loop through selectedusers array and append each element individually
                    selectedusers.forEach(userId => {
                        bodyFormData.append("user_ids[]", userId);
                    });
                }
                
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/teams`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },
        getTeamDetails(id,params){
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/teams/${id}`,
                    params
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },
        UpdateTeam(obj,userSelect,teamid) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                if (obj.name !== null) {
                    bodyFormData.append("name", obj.name);
                }
                if (obj.description !== null) {
                    bodyFormData.append("description", obj.description);
                }
                if (userSelect !== null) {
                    // Loop through selectedusers array and append each element individually
                    userSelect.forEach(userId => {
                        bodyFormData.append("user_ids[]", userId);
                    });
                }
                
                bodyFormData.append("_method", 'PUT');
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/teams/${teamid}`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },
        deleteTeam(id){
            return new Promise((resolve, reject) => {
                const options = {
                    method: "DELETE",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/teams/${id}`,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },
        removeUserFromTeams(teamid,userid) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                
                bodyFormData.append("_method", 'PUT');
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/teams/${teamid}/users/${userid}`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },
    }
}