export default {
  methods: {
    getAssessments(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/assessments`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    createNewTPRAssessment(data) {
      return new Promise((resolve, reject) => {
        const excludedList = [
          "tags",
          "selectedQuestionnaires",
          "selectedThirdParties",
        ];
        const bodyFormData = new FormData();

        for (let item in data) {
          if (
            data[item] &&
            data[item] != null &&
            !excludedList.includes(item)
          ) {
            if(item==='selected_all_questionnaires'||item==='include_all_vendors'){
              bodyFormData.append(item, data[item]?1:0);
            }else
            bodyFormData.append(item, data[item]);
          }
          if (item == "tags") {
            data[item].forEach((tag, index) => {
              bodyFormData.append(`tags[${index}][tag_title]`, `${tag.name}`);

              if (tag && tag.isNew) {
                bodyFormData.append(`tags[${index}][is_new]`, 1);
              } else {
                bodyFormData.append(`tags[${index}][tag_id]`, tag.value);
              }
            });
          }
          if (item == "selectedQuestionnaires"&&!data.selected_all_questionnaires) {
            data[item].forEach((qstnr, index) => {
              bodyFormData.append(`questionnaire_ids[${index}]`, qstnr._id);
            });
          }

          if (item == "selectedThirdParties"&&!data.include_all_vendors) {
            data[item].forEach((tprty, index) => {
              bodyFormData.append(`vendor_ids[${index}]`, tprty._id);
            });
          }
        }

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/assessments`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    updateTPRAssessment(data, assessmentId) {
      return new Promise((resolve, reject) => {
        const excludedList = [
          "tags",
          "selectedQuestionnaires",
          "selectedThirdParties",
        ];
        const bodyFormData = new FormData();

        for (let item in data) {
          if (
            data[item] &&
            data[item] != null &&
            !excludedList.includes(item)
          ) {
            if(item==='selected_all_questionnaires'||item==='include_all_vendors'){
              bodyFormData.append(item, data[item]?1:0);
            }else
            bodyFormData.append(item, data[item]);
          }
          if (item == "tags") {
            data[item].forEach((tag, index) => {
              bodyFormData.append(`tags[${index}][tag_title]`, `${tag.name}`);

              if (tag && tag.isNew) {
                bodyFormData.append(`tags[${index}][is_new]`, 1);
              } else {
                bodyFormData.append(`tags[${index}][tag_id]`, tag.value);
              }
            });
          }
          if (item == "selectedQuestionnaires"&&!data.selected_all_questionnaires) {
            data[item].forEach((qstnr, index) => {
              bodyFormData.append(`questionnaire_ids[${index}]`, qstnr._id);
            });
          }
          
          if (item == "selectedThirdParties"&&!data.include_all_vendors) {
            data[item].forEach((tprty, index) => {
              bodyFormData.append(`vendor_ids[${index}]`, tprty._id);
            });
          }
        }

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/assessments/${assessmentId}`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    createAssessment(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        if (obj.title !== null) {
          bodyFormData.append("title", obj.title);
        }
        if (obj.description !== null) {
          bodyFormData.append("description", obj.description);
        }

        obj.selectedTags.map((tag, i) => {
          bodyFormData.append(`tags[${i}][is_new]`, `${tag.is_new ? 1 : 0}`);
          if (tag.is_new === true) {
            bodyFormData.append(`tags[${i}][tag_title]`, `${tag.tag_title}`);
          }
          bodyFormData.append(
            `tags[${i}][tag_id]`,
            `${tag.tag_id ? tag.tag_id : ""}`
          );
        });

        // vendor Types
        if (obj.vendors.vendorInputType === 2) {
          if (obj.vendors.selectedVendors.length > 0) {
            obj.vendors.selectedVendors.map((item, i) => {
              bodyFormData.append(`vendor_ids[${i}]`, item._id);
            });
          }
        } else if (obj.vendors.vendorInputType === 1) {
          if (obj.selectedCategories.length > 0) {
            obj.selectedCategories.map((item, i) => {
              bodyFormData.append(`category_ids[${i}]`, item.value);
            });
          }
          if (obj.vendors.exclution.selectedVendors.length > 0) {
            obj.vendors.exclution.selectedVendors.map((item, i) => {
              bodyFormData.append(`exclude_vendor_ids[${i}]`, item._id);
            });
          }
        } else if (obj.vendors.vendorInputType === 0) {
          bodyFormData.append("include_all_vendors", 1);
          if (obj.vendors.exclution.selectedVendors.length > 0) {
            obj.vendors.exclution.selectedVendors.map((item, i) => {
              bodyFormData.append(`exclude_vendor_ids[${i}]`, item._id);
            });
          }
        }

        // ----------------------

        if (obj.selectedQuestionnaire.length > 0) {
          obj.selectedQuestionnaire.map((item, i) => {
            bodyFormData.append(`questionnaire_ids[${i}]`, item._id);
          });
        }

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/assessments`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateAssessment(obj, id) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        if (obj.title !== null) {
          bodyFormData.append("title", obj.title);
        }
        if (obj.description !== null) {
          bodyFormData.append("description", obj.description);
        }

        obj.selectedTags.map((tag, i) => {
          bodyFormData.append(`tags[${i}][is_new]`, `${tag.is_new ? 1 : 0}`);
          if (tag.is_new === true) {
            bodyFormData.append(`tags[${i}][tag_title]`, `${tag.tag_title}`);
          }
          bodyFormData.append(
            `tags[${i}][tag_id]`,
            `${tag.tag_id ? tag.tag_id : ""}`
          );
        });

        // vendor Types
        if (obj.vendors.vendorInputType === 2) {
          if (obj.vendors.selectedVendors.length > 0) {
            obj.vendors.selectedVendors.map((item, i) => {
              bodyFormData.append(`vendor_ids[${i}]`, item._id);
            });
          }
        } else if (obj.vendors.vendorInputType === 1) {
          if (obj.selectedCategories.length > 0) {
            obj.selectedCategories.map((item, i) => {
              bodyFormData.append(`category_ids[${i}]`, item.value);
            });
          }
          if (obj.vendors.exclution.selectedVendors.length > 0) {
            obj.vendors.exclution.selectedVendors.map((item, i) => {
              bodyFormData.append(`exclude_vendor_ids[${i}]`, item._id);
            });
          }
        } else if (obj.vendors.vendorInputType === 0) {
          bodyFormData.append("include_all_vendors", 1);
          if (obj.vendors.exclution.selectedVendors.length > 0) {
            obj.vendors.exclution.selectedVendors.map((item, i) => {
              bodyFormData.append(`exclude_vendor_ids[${i}]`, item._id);
            });
          }
        }

        // ----------------------

        if (obj.selectedQuestionnaire.length > 0) {
          obj.selectedQuestionnaire.map((item, i) => {
            bodyFormData.append(`questionnaire_ids[${i}]`, item._id);
          });
        }
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/assessments/${id}`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getAssessmentDetails(assessmentId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/assessments/${assessmentId}`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getAssessmentCategories(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/categories`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    createNewVendorCategory(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        if (obj.title !== null) {
          bodyFormData.append("title", obj.title);
        }
        if (obj.description !== null) {
          bodyFormData.append("description", obj.description);
        }

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/categories`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getAssessmentQuestionnaires(assessmentId, questionnaireId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/assessments/${assessmentId}/questionnaires/${questionnaireId}`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getAssessmentQuestionnaireResponses(params, assessmentId, questionnaireId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/assessments/${assessmentId}/questionnaires/${questionnaireId}/responses`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getAssessmentQuestionnaireResponseDetails(
      assessmentId,
      questionnaireId,
      responseId
    ) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/assessments/${assessmentId}/questionnaires/${questionnaireId}/responses/${responseId}`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    updateVendorQuestionnaireResponse(
      obj,
      vendorId,
      assessmentId,
      questionnaireId,
      responseId
    ) {
      console.log(vendorId, assessmentId, questionnaireId, responseId);
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        if (obj.recommendation !== null) {
          bodyFormData.append("recommendation", obj.recommendation);
        }
        if (obj.response !== null) {
          bodyFormData.append("response", obj.response);
        }
        if (obj.responseText !== null) {
          bodyFormData.append("response_text", obj.response_text);
        }
        if (obj.observation !== null) {
          bodyFormData.append("observation", obj.observation);
        }
        if (obj.note !== null) {
          bodyFormData.append("note", obj.note);
        }
        if (obj["evidenceFiles"] && obj.evidenceFiles !== null) {
          for (let i = 0; i < obj.evidenceFiles.length; i++) {
            bodyFormData.append(`evidence_files[${i}]`, obj.evidenceFiles[i]);
          }
        }
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}/assessments/${assessmentId}/questionnaires/${questionnaireId}/responses/${responseId}`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getAssessmentVendorResponses(assessmentId, vendorId, params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/assessments/${assessmentId}/vendor/${vendorId}`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getAssessmentResponsesDetails(assessmentId, questionnaireId, responseId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/assessments/${assessmentId}/questionnaires/${questionnaireId}/responses/${responseId}`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
