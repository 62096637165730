<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
    class="h-100"
  >
    <div class="card h-100">
      <div class="card-header border-bottom d-flex">
        <div
          style="width: 70%"
          class="d-flex flex-column justify-content-center align-items-start"
        >
          <h2 class="font-weight-bolder">
            <span class="align-middle"
              >Add new User to Thirdparty</span
            >
          </h2>
          <p class="card-subtitle text-muted pt-75">
            Fill the form below and click submit to add a new user to this
            Thirdparty.
          </p>
        </div>

        <div class="d-flex justify-content-end align-items-center">
           <b-button size="sm" @click="closeSidebar()" variant="outline-secondary"
            ><feather-icon size="24" icon="XIcon"
          /></b-button>
        </div>
      </div>
      <div class="card-body pt-3 h-100">
        <validation-observer ref="add_vendor_form" #default="{ invalid }">
          <b-form class="mt-2" @submit.prevent="handleSubmitClick()">
            <!-- First Name & Last Name -->
            <div class="d-flex align-items-center justify-content-between">
              <b-form-group label="First Name" style="width: 47%">
                <template v-slot:label>
                  First Name <span class="text-danger">*</span>
                  </template>
                <validation-provider
                  #default="{ errors }"
                  vid="first_name"
                  name="First Name"
                  :rules="{
                    required: true,
                    min: 3,
                  }"
                >
                  <b-form-input
                    v-model="formData.firstName"
                    id="first_name"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Last Name" style="width: 47%">
                <template v-slot:label>
                  Last Name <span class="text-danger">*</span>
                  </template>
                <validation-provider
                  #default="{ errors }"
                  vid="last_name"
                  name="Last Name"
                  :rules="{
                    required: true,
                  }"
                >
                  <b-form-input
                    v-model="formData.lastName"
                    id="last_name"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <b-form-group label="Email" class="w-100">
              <template v-slot:label>
                Email <span class="text-danger">*</span>
                  </template>
              <validation-provider
                #default="{ errors }"
                vid="user_id"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="formData.email"
                  id="email"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Mobile" class="w-100">
              <template v-slot:label>
                Mobile <span class="text-danger">*</span>
                  </template>
              <validation-provider
                #default="{ errors }"
                vid="mobile"
                name="Mobile"
                rules="required|digits:10"
              >
                <b-form-input
                  v-model="formData.mobile"
                  id="mobile"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="mt-2">
              <validation-provider
                #default="{ errors }"
                vid="should_invite"
                name="Invitation Email"
              >
                <b-form-checkbox
                  id="should_invite"
                  v-model="formData.shouldInvite"
                  :value="true"
                  :unchecked-value="false"
                >
                  Send Invitation Email to User
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="w-100 d-flex justify-content-center align-items-center">
              <b-button
                type="submit"
                variant="primary"
                class="mt-1 w-25"
                :disabled="invalid"
              >
                <feather-icon icon="CheckIcon" class="mr-50" />
                <span class="align-middle">Submit</span>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min } from "@validations";

import UtilsMixins from "../../../mixins/UtilsMixins";
import ThirdPartyRisksMixins from "../../../mixins/ThirdPartyRisksMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";

export default {
  components: {
    BOverlay,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    required,
    min,
  },
  data() {
    return {
      showOverlay: false,
      formData: {
        firstName: null,
        lastName: null,
        email: null,
        mobile: null,
        shouldInvite: false,
      },
    };
  },
  mixins: [UtilsMixins, ThirdPartyRisksMixins, ResponseMixins],
  props: {
    closeSidebar: {
      type: Function,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
    vendorId: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleSubmitClick() {
      this.showOverlay = true;
      this.addNewUserToVendor(this.formData, this.vendorId)
        .then((res) => {
          this.reloadParent();
          this.handleResponse(res);
          this.closeSidebar();
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
          if (err.response.status === 422) {
            this.$refs.add_vendor_form.setErrors(err.response.data.errors);
          }
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style>
</style>