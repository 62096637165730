import { render, staticRenderFns } from "./AddAssessmentToVendorForm.vue?vue&type=template&id=6b8f53fe&scoped=true&"
import script from "./AddAssessmentToVendorForm.vue?vue&type=script&lang=js&"
export * from "./AddAssessmentToVendorForm.vue?vue&type=script&lang=js&"
import style0 from "./AddAssessmentToVendorForm.vue?vue&type=style&index=0&id=6b8f53fe&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b8f53fe",
  null
  
)

export default component.exports