<template>
    <div class="w-100 d-flex align-items-end justify-content-start mb-2 " style="width:250px ">
      <b-avatar
        class="mr-1"
        variant="light-primary"
        :text="avatarText(`${firstName} ${lastName}`)"
      />
  
      <div
        class="rounded bg-light-primary px-1 py-75 d-flex flex-column align-items-start justify-content-between"
        style="width: 55%"
      >
      
  
        <div class="w-100 d-flex align-items-center justify-content-start">
          <div>
            <p class="font-weight-bold mb-0 text-dark">
              <span class="d-flex align-items-center justify-content-start">
                <feather-icon icon="UserIcon" size="14" class="mr-25" />
                <span>{{ `${firstName} ${lastName}` }}</span>
                 <!-- <span>test reviewer</span> -->
              </span>
            </p>
          </div>
          <div class="ml-2">
            <!-- <p
              class="font-weight-bold mb-0 cursor-pointer text-secondary"
              v-b-tooltip.hover
              :title="formatDateTime(dateTime)"
            >
              <span class="d-flex align-items-center justify-content-start">
                <feather-icon icon="ClockIcon" size="14" class="mr-25" />
                <span>
                  {{ formatDate(dateTime) }}
                </span>
              </span>
            </p> -->
          </div>
        <b-badge  :variant="status===1?'warning':status===5||status===6?'danger':'success'" class="ml-auto">{{ status===1?'Pending':status===5?'Reject':status===6?'Closed':'Approved' }}</b-badge>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <p class="text-dark mb-25 ml-2 mt-1">{{ message }}</p><br/>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { BAvatar, BBadge, VBTooltip } from "bootstrap-vue";
  import { avatarText } from "@core/utils/filter";
  import moment from "moment";
  
  export default {
    setup() {
      return { avatarText };
    },
    directives: {
      "b-tooltip": VBTooltip,
    },
    components: { BAvatar,BBadge },
    props: {
      firstName: {
        type: String,
        required: true,
        default:'test'
      },
  
      lastName: {
        type: String,
        required: true,
        default:'user'
      },
  
      message: {
        type: String,
        // required: true,
      },
  
      status: {
        type: Number,
        default:1
      },
    },
    filters: {
      formatMoment: function (date) {
        return moment(date).format("D-MMM-YY");
      },
    },
  
    methods: {
      formatDate(date) {
        const daysAgo = moment().diff(date, "days");
        const weeksAgo = moment().diff(date, "weeks");
        const monthsAgo = moment().diff(date, "months");
  
        if (daysAgo === 0) {
          return "Today";
        } else if (daysAgo === 1) {
          return "Yesterday";
        } else if (daysAgo < 7) {
          return `${daysAgo} days ago`;
        } else if (weeksAgo === 1) {
          return "1 week ago";
        } else if (weeksAgo < 4) {
          return `${weeksAgo} weeks ago`;
        } else if (monthsAgo === 1) {
          return "1 month ago";
        } else {
          return `${monthsAgo} months ago`;
        }
      },
  
      formatDateTime(date) {
        // Utilize Moment.js to format date and time
        return moment(date).format("D-MMM-YY");
      },
  
      getDateDifference(startDate, endDate) {
        const timestamp1 = new Date(startDate).getTime();
        const timestamp2 = new Date(endDate).getTime();
  
        const timeDifference = Math.abs(timestamp2 - timestamp1);
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  
        return daysDifference;
      },
    },
  };
  </script>
  
  <style></style>
  